import PageTitle from '../components/ui/PageTitle';
import RoleForm from '../components/pages/Roles/RoleForm';
import FormButtons from '../components/ui/FormButtons';

const AddRole = () => {
  return (
    <div>
      <div className='flex flex-row justify-between'>
        <PageTitle title='Add Role' />
        <FormButtons form='addRole' />
      </div>
      <RoleForm type="add"/>
    </div>
  );
};
export default AddRole;
