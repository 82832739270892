import DetailsContentHeaderWButton from '../../ui/DetailsContent/DetailsContentHeaderWButton';
import DetailsContentRow from '../../ui/DetailsContent/DetailsContentRow';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import HistoryPanel from '../../pages/History/HistoryPanel';
import Moment from 'moment';
import PropTypes from 'prop-types';
import RoleDetailsPanel from '../../pages/Roles/RoleDetailsPanel';
import Tabs from '../../ui/Tabs';

import { useNavigate } from 'react-router-dom';
import { defaultProps } from './ServiceTokens-Constants';
import { useState } from 'react';

const propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  history: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  serviceToken: PropTypes.string,
};

const ServiceTokenDetailsPanel = (props) => {
  const { createdAt, createdBy, history, name, role, serviceToken } = props;
  const [openTab, setOpenTab] = useState(1);
  const navigate = useNavigate();
  const [tabs] = useState([
    {
      name: 'Access',
      href: '#',
      current: true,
      handleClick: () => handleTabClick(1),
    },
    {
      name: 'History',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(2),
    },
  ]);

  const handleTabClick = (tab) => {
    let currentTab = tabs.find((tab) => tab.current);
    currentTab.current = false;
    tabs[tab - 1].current = true;
    setOpenTab(tab);
  };

  const onClick = () => {
    navigate('/edit-token', {
      state: {
        values: {
          createdAt: createdAt,
          createdBy: createdBy,
          history: history,
          name: name,
          role: role,
          serviceToken: serviceToken,
        },
      },
    });
  };

  return (
    <>
      <DetailsContentWrapper>
        <DetailsContentHeaderWButton
          content={name}
          subContent={serviceToken}
          text='Edit'
          onClick={onClick}
        />
        <DetailsContentRow content={role} label='Role' />
        <DetailsContentRow content={createdBy} label='Created By' />
        <DetailsContentRow
          content={`${Moment(createdAt).format('MM-DD-YYYY hh:mm:ss Z')} UTC`}
          label='Created Date'
        />
      </DetailsContentWrapper>
      <DetailsContentWrapper styles='relative z-10'>
        <Tabs tabs={tabs} />
        {openTab === 1 && <RoleDetailsPanel role={role} />}
        {openTab === 2 && <HistoryPanel user={name} />}
      </DetailsContentWrapper>
    </>
  );
};

ServiceTokenDetailsPanel.propTypes = propTypes;
ServiceTokenDetailsPanel.defaultProps = defaultProps;

export default ServiceTokenDetailsPanel;
