import { defaultProps, propTypes, showButton } from './PageTitle-Constants';

const PageTitle = (props) => {
  const { clsColor, buttonText, onClick, title } = props;

  return (
    <>
      <div className={`flex text-3xl font-bold mb-10 ${clsColor}`}>
        {title}
        {showButton(buttonText, onClick)}
      </div>
    </>
  );
};

PageTitle.propTypes = propTypes;
PageTitle.defaultProps = defaultProps;

export default PageTitle;
