import Button from '../components/ui/Button';

import { useContext, useEffect } from 'react';
import { UserContext } from '../App';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.user) {
      navigate('/roles');
    }
  }, [user, navigate]);

  const google = () => {
    var google_url =
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/auth/google';
    window.open(google_url, '_self');
  };

  return (
    <div className='home'>
      <div className='flex justify-center text-center w-full'>
        <Button
          clsBgColor='bg-green'
          text='Login'
          type='button'
          onClick={google}
        />
      </div>
    </div>
  );
};

export default Home;
