import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Popover, Transition } from '@headlessui/react';

const RowEndMenu = (props) => {
  const { isToken, row, onAccessClick, onEditClick } = props;
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Popover className='relative'>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-gray-500' : 'text-white',
              'group inline-flex items-center rounded-md text-xl font-medium hover:text-gray-900 focus:outline-none'
            )}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} aria-hidden='true' />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel className='absolute left-1/2 z-10 -mt-1 -translate-x-3/4  transform px-2 text-sm w-24'>
              <div className='overflow-hidden rounded shadow-lg'>
                <div className='relative bg-bg-input text-black'>
                  {isToken && (
                    <button
                      type='button'
                      className='text-sm p-2 hover:bg-gray-800 text-gray-900 hover:text-white hover:cursor-pointer'
                    >
                      <p>Copy Token</p>
                    </button>
                  )}
                  <button
                    type='button'
                    className='p-2 hover:bg-gray-800 text-gray-900 hover:text-white hover:cursor-pointer'
                    onClick={() => onAccessClick(row)}
                  >
                    <p>Access</p>
                  </button>
                  {!isToken && (
                    <>
                      <button
                        type='button'
                        className='p-2 hover:bg-gray-800 text-gray-900 hover:text-white hover:cursor-pointer'
                      >
                        <p>Users</p>
                      </button>
                      <button
                        type='button'
                        className='p-2 hover:bg-gray-800 text-gray-900 hover:text-white hover:cursor-pointer'
                      >
                        <p>Tokens</p>
                      </button>
                    </>
                  )}
                  <button
                    type='button'
                    className='p-2 hover:bg-gray-800 text-gray-900 hover:text-white hover:cursor-pointer'
                    onClick={() => onEditClick(row)}
                  >
                    <p>Edit</p>
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default RowEndMenu;
