export const getServerConfigData = async (setError, setIsLoaded, setItems) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/config-admin/list',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const getServerConfigDetails = async (
  name,
  setError,
  setIsLoaded,
  setJsonConfig
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/config-admin/find',
      {
        method: 'POST',
        body: JSON.stringify({
          name: name,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setJsonConfig(resObject[0].json);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const addServerConfigData = async (
  name,
  json,
  type,
  navigate,
  setError,
  setIsLoaded
) => {
  try {
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/config-admin/add',
      {
        method: 'PUT',
        body: JSON.stringify({
          name: name,
          json: json,
          type: type,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    await navigate('/server-config');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const writeServerConfig = async (
  json,
  name,
  navigate,
  setError,
  setIsLoaded
) => {
  try {
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/config-admin/update',
      {
        method: 'PATCH',
        body: JSON.stringify({
          name: name,
          json: json,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    navigate('/server-config');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};
