import PageTitle from '../components/ui/PageTitle';

const Communications = () => {
  return (
    <div>
      <PageTitle title='Communications' />
      <div className='communications'></div>
    </div>
  );
};

export default Communications;
