import PageTitle from '../components/ui/PageTitle';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import HistoryDetailsPanel from '../components/pages/History/HistoryDetailsPanel';

const HistoryDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = location.state.pathName;
  const [prevLink, setPrevLink] = useState('');
  const [user] = useState(location?.state?.user || null);

  useEffect(() => {
    if (prevLocation === '/profile') {
      setPrevLink('Account Profile');
    } else if (prevLocation === '/user-details') {
      setPrevLink('User Details');
    } else {
      setPrevLink('History');
    }
  }, [prevLocation]);

  const setNavigate = () => {
    if (user) {
      navigate('/user-details', {
        state: {
          values: user,
        },
      });
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <div className='flex flex-row'>
        <button
          className='text-xl flex-none w-fit py-4 text-black'
          onClick={() => setNavigate()}
        >
          <FontAwesomeIcon icon={faLeftLong} className='mr-2' />
          {prevLink}
        </button>
      </div>
      <PageTitle title='Access Record Details' />
      <HistoryDetailsPanel />
    </div>
  );
};
export default HistoryDetails;
