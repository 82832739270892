export const fetchServiceTokenData = async (
  setError,
  setItems,
  setIsLoaded
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/service-access/token/list',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const fetchServiceTokenDataUser = async (
  email,
  setError,
  setItems,
  setIsLoaded
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/service-access/token/user',
      {
        method: 'POST',
        body: JSON.stringify({
          user: email,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const fetchServiceTokenDataRole = async (
  role,
  setError,
  setItems,
  setIsLoaded
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/tokens',
      {
        method: 'POST',
        body: JSON.stringify({
          role: role,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const deleteToken = async (
  name,
  navigate,
  setError,
  setIsLoaded,
  setIsSubmitting
) => {
  try {
    setIsSubmitting(true);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/service-access/token/remove',
      {
        method: 'DELETE',
        body: JSON.stringify({
          name: name,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    await navigate('/service');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const addToken = async (
  form,
  navigate,
  setIsSubmitting,
  setIsLoaded,
  setError,
  values
) => {
  try {
    setIsSubmitting(true);
    let formtType = 'add';
    let method = 'PUT';
    if (values) {
      formtType = 'update';
      method = 'PATCH';
    }
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/service-access/token/' +
        formtType,
      {
        method: method,
        body: JSON.stringify({
          name: form.name,
          role: form.role,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    setIsLoaded(true);
    navigate('/service');
  } catch (err) {
    setIsLoaded(true);
    setError(err);
  }
};
