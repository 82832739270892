import WorldConfigPanel from '../components/pages/WorldConfig/WorldConfigPanel';
import PageTitle from '../components/ui/PageTitle';

const WorldConfig = () => {
  return (
    <div>
        <div>
            <PageTitle title='World Config' />
        </div>
      <WorldConfigPanel></WorldConfigPanel>
    </div>
  );
};

export default WorldConfig;
