import { defaultProps, propTypes, renderTabs } from './Tabs-Constants';

const Tabs = (props) => {
  const { tabs } = props;

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div>
      <div>
        <div className='border-b border-gray mb-2'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {renderTabs(classNames, tabs)}
          </nav>
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
