import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownWideShort,
  faArrowUpWideShort,
} from '@fortawesome/free-solid-svg-icons';

const Table = (props) => {
  const {
    clickableRow,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = props;
  return (
    <table
      {...getTableProps()}
      className='w-full table-auto border-collapse mb-8 '
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className='border-b-2 border-white'
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}
                className='p-2 bg-dark-gray font-bold text-white text-lg text-left px-8'
              >
                <span {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  <span className='pl-2'>
                    {!column.disableSortBy ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faArrowDownWideShort} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowUpWideShort} />
                        )
                      ) : (
                        <span className='text-[#78797C]'>
                          <FontAwesomeIcon icon={faArrowDownWideShort} />
                        </span>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={` odd:bg-table-row-odd even:bg-dark-gray ${
                clickableRow ? 'hover:bg-table-row-hover cursor-pointer' : null
              }`}
              style={{ height: '1px' }}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className='border-b border-gray text-white min-h-[40px] px-8'
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
