import SvelteJSONEditor from '../../ui/SvelteJsonEditor';

const ServerConfigEditFormJSON = (props) => {
  const { content, setContent } = props;
  return (
    <div className='p-8 mt-4 jse-theme-dark'>
      <SvelteJSONEditor
        content={content}
        mode={'text'}
        readOnly={false}
        onChange={setContent}
      />
    </div>
  );
};

export default ServerConfigEditFormJSON;
