import AddRole from './routes/AddRole';
import AddServerConfig from './routes/AddServerConfig';
import AddToken from './routes/AddToken';
import Communications from './routes/Communications';
import ServerConfig from './routes/ServerConfig';
import EditICSData from './routes/EditICSData';
import EditRole from './routes/EditRole';
import EditToken from './routes/EditToken';
import EditServerConfig from './routes/EditServerConfig';
import EditUser from './routes/EditUser';
import History from './routes/History';
import HistoryDetails from './routes/HistoryDetails';
import Home from './routes/Home';
import ICSData from './routes/ICSData';
import Mongo from './routes/Mongo';
import Patcher from './routes/Patcher';
import Profile from './routes/Profile';
import Players from './routes/Players';
import Roles from './routes/Roles';
import RoleDetails from './routes/RoleDetails';
import ServerConfigDetails from './routes/ServerConfigDetails';
import Service from './routes/Service';
import ServiceTokenDetails from './routes/ServiceTokenDetails';
import User from './routes/User';
import UserDetails from './routes/UserDetails';
import WorldConfig from './routes/WorldConfig';

import { Route } from 'react-router-dom';

export const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/add-role',
    element: <AddRole />,
  },
  {
    path: '/add-token',
    element: <AddToken />,
  },
  {
    path: '/add-server-config',
    element: <AddServerConfig />,
  },
  {
    path: '/communications',
    element: <Communications />,
  },

  {
    path: '/edit-ics-data',
    element: <EditICSData />,
  },
  {
    path: '/edit-role',
    element: <EditRole />,
  },
  {
    path: '/edit-server-config',
    element: <EditServerConfig />,
  },
  {
    path: '/edit-token',
    element: <EditToken />,
  },
  {
    path: '/edit-user',
    element: <EditUser />,
  },
  {
    path: '/history',
    element: <History />,
  },
  {
    path: '/history-details',
    element: <HistoryDetails />,
  },
  {
    path: '/ics-data',
    element: <ICSData />,
  },
  {
    path: '/mongo',
    element: <Mongo />,
  },
  {
    path: '/patcher',
    element: <Patcher />,
  },
  {
    path: '/players',
    element: <Players />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/roles',
    element: <Roles />,
  },
  {
    path: '/role-details',
    element: <RoleDetails />,
  },
  {
    path: '/server-config',
    element: <ServerConfig />,
  },
  {
    path: '/server-config-details',
    element: <ServerConfigDetails />,
  },
  {
    path: '/service',
    element: <Service />,
  },
  {
    path: '/token-details',
    element: <ServiceTokenDetails />,
  },
  {
    path: '/user',
    element: <User />,
  },
  {
    path: '/user-details',
    element: <UserDetails />,
  },
  {
    path: '/world-configs',
    element: <WorldConfig />,
  },
];

export const paths = {
  ROOT: '/',
  ADDROLE: '/add-role',
  ADDSERVERCONFIG: '/add-server-config',
  ADDTOKEN: '/add-token',
  COMMUNICATIONS: '/connunications',
  EDITICSDATA: '/edit-ics-data',
  EDITROLE: '/edit-role',
  EDITREDITSERVERCONFIG: '/edit-server-config',
  EDItTOKEN: '/edit-token',
  EDITUSER: '/edit-user',
  HISTORY: '/history',
  HISTORYDETAILS: '/history-details',
  ICSDATA: '/ics-data',
  PATCHER: '/patcher',
  PLAYERS: '/players',
  PROFILE: '/profile',
  ROLES: '/roles',
  ROLEDETAILS: '/role-details',
  SERVERCONFIG: '/server-config',
  SERVERCONFIGDETAILS: '/server-config-details',
  SERVICE: '/service',
  SERVICETOKENDEATILS: '/token-details',
  SERVICETOKENEDIT: '/edit-token',
  USER: '/user',
  USERDETAILS: '/user-details',
  WORLDCONFIG: '/world-configs',
};

export const routeElements = routes.map(({ path, element }, key) => {
  return <Route element={element} key={key} path={path} />;
});
