import DetailsContentHeaderWButton from '../../ui/DetailsContent/DetailsContentHeaderWButton';
import DetailsContentRow from '../../ui/DetailsContent/DetailsContentRow';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import Moment from 'moment';
import PropTypes from 'prop-types';
import StatusHighlightButton from '../../ui/StatusHighlightButton';

import { defaultProps } from './User-Constants';
import { useNavigate } from 'react-router-dom';

const propTypes = {
  createdAt: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  googleId: PropTypes.string,
  image: PropTypes.string,
  role: PropTypes.string,
};

const UserDetailPanel = (props) => {
  const { displayName, role, createdAt, image, mongoUser, googleId, email } =
    props;

  const navigate = useNavigate();

  const onClick = () => {
    navigate('/edit-user', {
      state: {
        values: {
          createdAt: createdAt,
          displayName: displayName,
          email: email,
          mongoUser: mongoUser,
          role: role,
          googleId: googleId,
        },
      },
    });
  };

  return (
    <DetailsContentWrapper>
      <DetailsContentHeaderWButton
        content={displayName}
        image={image}
        subContent={email}
        text='Edit'
        onClick={onClick}
      />
      <DetailsContentRow
        content={<StatusHighlightButton text={role} />}
        label='Role'
      />
      <DetailsContentRow content={googleId} label='GoogleID' />
      <DetailsContentRow
        content={`${Moment(createdAt).format('MM-DD-YYYY hh:mm:ss Z')} UTC`}
        label='Created Date'
      />
    </DetailsContentWrapper>
  );
};

UserDetailPanel.propTypes = propTypes;
UserDetailPanel.defaultProps = defaultProps;

export default UserDetailPanel;
