import Button from '../../ui/Button';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import Modal from '../../ui/Modal';

import { deleteToken } from './ServiceTokenService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ServiceTokenDelete = (props) => {
  const { name } = props;

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <>
        <form id='deleteToken'>
          <DetailsContentWrapper>
            <div className='py-4 px-8'>
              <div>
                <h3 className='text-2xl font-bold mb-4'>Delete Token</h3>
              </div>
              <div>
                Remove this token. This will immediatly disrupt any systems
                currently using this token fir perform opperations.
                <br />
                This is permanent and connot be undone.
              </div>
              <div className='mt-4 flex justify-start -ml-8'>
                <Button
                  form='deleteToken'
                  text='Delete Token'
                  type='button'
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>
          </DetailsContentWrapper>
        </form>
        <Modal
          buttonName='Delete Token'
          content={`Remove this token. This will immediatly disrupt any systems
                currently using this token fir perform opperations. This is permanent and connot be undone. Are you sure you wish to proceed?`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Delete ${name} `}
          onClick={() =>
            deleteToken(name, navigate, setError, setIsLoaded, setIsSubmitting)
          }
        />
      </>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default ServiceTokenDelete;
