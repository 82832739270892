import PropTypes from 'prop-types';

export const propTypes = {
  clsBgColor: PropTypes.string,
  clsBorderColor: PropTypes.string,
  text: PropTypes.string,
};

export const defaultProps = {
  clsBgColor: 'bg-blue',
  clsBorderColor: 'border border-light-blue',
  text: null,
};
