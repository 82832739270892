import Button from '../../ui/Button';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import PropTypes from 'prop-types';
import MongoRole from './MongoRole';
import { addMongoRoles } from './MongoDBService';
import {
  getMongoData,
  removeMongoRole,
  isMongoDBValid,
} from './MongoDBService';
import { defaultProps } from './Mongo-Constants';
import { useEffect, useState } from 'react';
import Modal from '../../ui/Modal';

const propTypes = {
  values: PropTypes.object,
};

const MongoRolesPanel = (props) => {
  const { setIsFormValid, items, setItems, values } = props;
  const [form, setForm] = useState(values);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = form.email.substring(0, form.email.indexOf('@'));
  const [modalOpen, setModalOpen] = useState(false);
  const [canAddNewResource, setCanAddNewResource] = useState(true);

  const onChangeDatabaseName = (e, index) => {
    const { name, value } = e.target;
    let tempForm = items;
    let tempResources = tempForm;
    let tempResource = tempResources[index];
    tempResource.role = 'read';
    setForm((state) => ({
      ...state,
    }));
    if (name === 'databaseName') {
      tempResource.db = value;
      setForm((state) => ({
        ...state,
      }));
    } else {
      tempResource.role = value;
      setForm((state) => ({
        ...state,
      }));
    }
    setItems(tempForm);
  };

  useEffect(() => {
    items.map(async (item) => {
      if (item.db !== '') {
        setCanAddNewResource(await isMongoDBValid(item.db));
        setIsFormValid(await isMongoDBValid(item.db));
      }
    });
  }, [items, setIsFormValid]);

  const onHandleAddResource = () => {
    setItems(
      items.concat({
        new: true,
        db: '',
        role: '',
      })
    );
  };

  const onHandleDelete = (data) => {
    let tempData = items;
    setItems(tempData.filter((item) => item.db !== data.db));
    tempData = tempData.filter((item) => item.db === data.db);
    const sendData = {
      user: user,
      roles: tempData,
    };
    removeMongoRole(sendData, setError, setIsLoaded);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let tempData = items;
    const newRolls = tempData.filter((item) => item.db !== "" || item.role !== "")
    await addMongoRoles(newRolls, user, setError, setIsLoaded);
    setItems(newRolls);
  };

  const showRoles = (onChangeDatabaseName, onHandleDelete) => {
    return items?.map((item, index) => {
      return (
        <MongoRole
          index={index}
          key={index}
          item={item}
          onChangeDatabaseName={(e) => onChangeDatabaseName(e, index)}
          onHandleDelete={() => onHandleDelete(item, index)}
          setIsFormValid={setIsFormValid}
          setCanAddNewResource={setCanAddNewResource}
        />
      );
    });
  };

  const removeAllRoles = () => {
    let tempData = items;
    setItems([]);
    const sendData = {
      user: user,
      roles: tempData,
    };
    removeMongoRole(sendData, setError, setIsLoaded);
    setModalOpen(false);
  };

  useEffect(() => {
    getMongoData(user, setError, setItems, setIsLoaded, setIsSubmitting);
  }, [setItems, user]);

  if (error) {
    if(error.message == "Unexpected end of JSON input"){
      return <div></div>;
    }else{return <div>Error: {error.message}</div>;}
    
  } else if (!isSubmitting) {
    return (
      <>
        <form id='addRole'>
          <DetailsContentWrapper>
            <div className='py-4 px-8'>
              <div className='flex flex-row justify-between'>
                <div>
                  <h3 className='text-2xl font-bold mb-4'>Mongo DB Access</h3>
                </div>
                <div className='flex flex-row justify-between'>
                  <Button
                    text='Remove All'
                    type='button'
                    clsBgColor='bg-gradient-to-b from-[#797877] to-[#2C2A29]'
                    onClick={() => setModalOpen(true)}
                  />
                  <Button
                    text='Submit'
                    type='button'
                    disabled={!canAddNewResource}
                    onClick={submitForm}
                  />
                </div>
                
              </div>
              {showRoles(onChangeDatabaseName, onHandleDelete)}
              <div className='mt-8 -ml-8'>
                <Button
                  text='Add Resource'
                  type='button'
                  onClick={() => onHandleAddResource()}
                  disabled={!canAddNewResource}
                />
              </div>
            </div>
          </DetailsContentWrapper>
        </form>
        <Modal
          buttonName='Confirm'
          content={`Are you sure you want ti revoke all database access? These credentials will no longer have access to view or make changes, but any information stored on MongoDB will remain intact. Access can be restored at any time.`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Remove All Mongo DB Access `}
          onClick={removeAllRoles}
        />
      </>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

MongoRolesPanel.propTypes = propTypes;
MongoRolesPanel.defaultProps = defaultProps;

export default MongoRolesPanel;
