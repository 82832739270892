import Button from '../../ui/Button';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoleDetailsUI } from './RoleService';

const RoleTitle = (props) => {
  const { role } = props;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    getRoleDetailsUI(role, setError, setIsLoaded, setResources);
  }, [role]);
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/edit-role', {
      state: {
        values: {
          role: role,
          resources: resources,
          type: 'edit',
        },
      },
    });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <DetailsContentWrapper>
        <div className='py-4 px-4'>
          <section className='flex flex-row justify-between'>
            <div className='py-1 ml-4 font-bold w-36 text-2xl'>
              <label>{role}</label>
            </div>
            <div className='text-right'>
              <Button text='Edit' type='button' onClick={onClick} />
            </div>
          </section>
        </div>
      </DetailsContentWrapper>
    );
  }
};

export default RoleTitle;
