import { filterDefaultProps, filterPropTypes } from './Table-Constants';

const FilterInputDropdown = (props) => {
  const { id, label, options, placeholder, setfilter, size } = props;

  const clsSize = `rounded-l block bg-bg-input p-2  text-black  h-10 placeholder:italic ${size}`;

  return (
    <div className='flex-1'>
      <label htmlFor={id} className='block font-bold text-sm text-black'>
        {label}
      </label>
      <div className='relative mt-1 flex flex-grow items-stretch focus-within:z-10'>
        <select
          className={clsSize}
          id={id}
          name={id}
          placeholder={placeholder}
          onChange={setfilter}
        >
          <option value=''>None</option>
          {options.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

FilterInputDropdown.propTypes = filterPropTypes;
FilterInputDropdown.defaultProps = filterDefaultProps;

export default FilterInputDropdown;
