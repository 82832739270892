import PageTitle from '../components/ui/PageTitle';
import ServerConfigDetailsPanel from '../components/pages/ServerConfig/ServerConfigDetailsPanel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const ServerConfigDetails = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='flex flex-row'>
        <button
          className='text-xl flex-none w-fit py-4 text-black'
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
          Server Config
        </button>
      </div>
      <PageTitle title='Server Config File' />
      <ServerConfigDetailsPanel />
    </div>
  );
};
export default ServerConfigDetails;
