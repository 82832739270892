import Button from '../../ui/Button';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import Modal from '../../ui/Modal';

import { deleteRoleUI } from './RoleService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RoleDelete = (props) => {
  const { role, resource } = props;

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <>
        <form id='deleteRole'>
          <DetailsContentWrapper>
            <div className='px-8 py-4'>
              <div>
                <h3 className='text-2xl font-bold mb-4'>Delete Role</h3>
              </div>
              <div>
                Remove this role and all associated permissions. Can only be
                done if role is not assigned to any users or tokens.
                <br />
                This is permanent and connot be undone.
              </div>
              <div className='mt-4 flex justify-start -ml-8'>
                <Button
                  form='deleteRole'
                  text='Delete Role'
                  type='button'
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>
          </DetailsContentWrapper>
        </form>
        <Modal
          buttonName='Delete Role'
          content={`Remove this role and all associated permissions. Can only be done if role is not assigned to any users or tokens. This is permanent and connot be undone. Are you sure you wish to proceed?`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Delete ${resource} `}
          onClick={() =>
            deleteRoleUI(navigate, role, setIsLoaded, setError, setIsSubmitting)
          }
        />
      </>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default RoleDelete;
