import DetailsContentRow from '../../ui/DetailsContent/DetailsContentRow';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import Moment from 'moment';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const HistoryDetailsPanel = () => {
  const location = useLocation();

  const [codeHeight, setCodeHeight] = useState('');
  const [request, setRequest] = useState(null);

  useEffect(() => {
    if (location.state.values.request) {
      setRequest(
        JSON.stringify(JSON.parse(location.state.values.request), null, 1)
      );
    } else {
      setRequest('No Request Found');
    }
    const code = document.querySelector('.code');
    const codeOffset = code.offsetHeight;
    setCodeHeight(codeOffset + 16 + 'px');
  }, [location.state.values.request, request]);

  return (
    <DetailsContentWrapper>
      <DetailsContentRow content={location.state.values.user} label='User' />
      <DetailsContentRow
        content={`${Moment(location.state.values.timestamp).format(
          'MM-DD-YYYY hh:mm:ss Z'
        )} UTC `}
        label='Timestamp'
      />
      <DetailsContentRow content='' label='IP Address' />
      <DetailsContentRow content='' label='Auth Info' />
      <DetailsContentRow
        content={location.state.values.message}
        label='Message'
      />
      <section className='flex my-5 px-8'>
        <div className='flex-none py-1 font-bold w-44'>
          <label>Request:</label>
        </div>
        <div
          className='flex-auto py-1 relative h-full code-container '
          style={{ height: codeHeight }}
        >
          <div className='p-4 bg-[#1E1E1E] border-[#3E4043] text-white absolute w-[98%] overflow-auto code'>
            <pre>{request}</pre>
          </div>
        </div>
      </section>
    </DetailsContentWrapper>
  );
};

export default HistoryDetailsPanel;
