import FormButtons from '../components/ui/FormButtons';
import MongoRolesPanel from '../components/pages/MongoDB/MongoRolesPanel';
import PageTitle from '../components/ui/PageTitle';
import UserDelete from '../components/pages/Users/UserDelete';
import UserForm from '../components/pages/Users/UserForm';
import { useLocation } from 'react-router-dom';
import { updateUserRole } from '../components/pages/Users/UserService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DetailsContentWrapper from '../components/ui/DetailsContent/DetailsContentWrapper';
import MongoDBDelete from '../components/pages/MongoDB/MongoDBDelete';

const EditUser = () => {
  const location = useLocation();
  const [form, setForm] = useState({
    displayName: location.state.values?.displayName || '',
    email: location.state.values?.email || '',
    role: location.state.values?.role || '',
  });
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const user = location.state.values.email.substring(
    0,
    location.state.values.email.indexOf('@')
  );
  const [isFormValid, setIsFormValid] = useState(true);

  const submitForm = async (e) => {
    e.preventDefault();
    const response = await updateUserRole(form, navigate, setError, setIsLoaded);
    let resObj = await response;
    if (resObj === 'added') {
    } else {
      setError({ message: 'there was an error' });
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <div>
        <div className='flex flex-row justify-between'>
          <PageTitle title='Edit User' />
          <FormButtons
            title='Submit'
            onClick={submitForm}
            disabled={!isFormValid}
          />
        </div>
        <UserForm
          values={location.state.values}
          form={form}
          navigate={navigate}
          setIsSubmitting={setIsSubmitting}
          setIsLoaded={setIsLoaded}
          setError={setError}
          setForm={setForm}
          error={error}
        />
        {location.state.values.mongoUser && (
          <MongoRolesPanel
            values={location.state.values}
            items={items}
            setItems={setItems}
            setIsFormValid={setIsFormValid}
          />
        )}
        <DetailsContentWrapper>
          <div>
            <h3 className='text-2xl font-bold mb-4 px-8 py-4'>
              Account Moderation
            </h3>
          </div>
          <MongoDBDelete user={user} />
          <UserDelete email={location.state.values.email} />
        </DetailsContentWrapper>
      </div>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default EditUser;
