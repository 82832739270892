import { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getUser } from './App-Service';
import Header from './components/ui/Header';
import Layout from './components/ui/Layout';
import Navbar from './components/ui/Navbar';
import { routeElements } from './App-Constants';
import Home from './routes/Home';

export const UserContext = createContext();

export default function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser(setUser);
  }, [user?.mongoUser]);

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ user, setUser }}>
        <Layout styles='px-24'>
          <Header />
          <Navbar />
          <Layout styles='pt-7'>
            <Routes>
              <Route path='/' element={<Home />} />
              {user && routeElements}
              <Route path='*' element={<Home />} />
            </Routes>
          </Layout>
        </Layout>
      </UserContext.Provider>
    </BrowserRouter>
  );
}
