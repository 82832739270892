import ServerConfigPanel from '../components/pages/ServerConfig/ServerConfigPanel';
import PageTitle from '../components/ui/PageTitle';

import { useNavigate } from 'react-router-dom';

const ServerConfig = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/add-server-config');
  };

  return (
    <div>
      <PageTitle
        buttonText='Add'
        title='Server Config'
        onClick={handleButtonClick}
      />
      <ServerConfigPanel />
    </div>
  );
};

export default ServerConfig;
