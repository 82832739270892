import PropTypes from 'prop-types';

import { defaultProps } from './Dropdown-Constants';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

const propTypes = {
  dropdown: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  image: PropTypes.string,
  logoutButton: PropTypes.bool,
  submenus: PropTypes.array,
  title: PropTypes.string,
};

const Dropdown = (props) => {
  const { id, image, logoutButton, submenus, title } = props;
  const [dropdown, setDropdown] = useState(null);
  const ref = useRef();

  useEffect(() => {
    const onClick = (e) => {
      if (!e.target.classList.contains('dropdown-toggle')) {
        setDropdown(null);
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [dropdown]);

  const displaySubMenus = submenus.map((submenu, index) => (
    <Link className='link' to={submenu.link} key={index}>
      <li
        className={`relative text-left px-2 py-2 font-normal hover:bg-light-orange`}
        value={submenu.title}
      >
        <div>{submenu.title}</div>
      </li>
    </Link>
  ));

  const dropdownArrow = (title, dropdown, id) => {
    if (title) {
      if (dropdown && id === dropdown) {
        return <FontAwesomeIcon icon={faCaretUp} />;
      }
      return <FontAwesomeIcon icon={faCaretDown} />;
    }
  };

  const logout = () => {
    window.open(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/auth/logout',
      '_self'
    );
  };

  const onDropdown = (dropdown, id, setDropdown) => {
    if (dropdown === id) {
      setDropdown(null);
    }
    if (dropdown !== id) {
      setDropdown(id);
    }
  };

  const openDropdown = (dropdown, id, image, logoutButton) => {
    if (id === dropdown)
      return (
        <div className={`relative ${image ? '-left-28' : '-left-4'}`}>
          <ul
            className={`dropdown absolute shadow z-50 rounded min-w-[10rem] bg-dark-gray text-white text-xl ${
              dropdown ? 'block' : 'hidden'
            }`}
          >
            {displaySubMenus}
            {showLogoutButton}
          </ul>
        </div>
      );
  };

  const showImage = image && (
    <img
      src={image}
      alt={title}
      className='h-12 rounded-full dropdown-toggle mt-4 inline-block mb-2'
    />
  );

  const showLogoutButton = logoutButton && (
    <li
      className={`logout relative text-left px-1 py-2 font-normal hover:bg-light-orange`}
      onClick={logout}
    >
      <button className='link'>Logout</button>
    </li>
  );

  return (
    <>
      <div className='float-left px-8'>
        <button
          aria-haspopup='menu'
          aria-expanded={dropdown ? 'true' : 'false'}
          className='dropdown-toggle font-normal'
          ref={ref}
          type='button'
          onClick={() => onDropdown(dropdown, id, setDropdown)}
        >
          {showImage}
          <span className='dropdown-toggle' value={id}>
            {title}
            <span className='ml-1 text-xs relative -top-1'>
              {dropdownArrow(title, dropdown, id)}
            </span>
          </span>
        </button>
        {openDropdown(dropdown, id, image, logoutButton, submenus)}
      </div>
    </>
  );
};

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
