import { getServerConfigData } from './ServerConfig-Service';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../ui/Table';
import Cell from '../../ui/Table/Cell';
import RowEndMenu from '../../ui/Table/RowEndMenu';
import FilterInput from '../../ui/Table/FilterInput';

const ServerConfigPanel = () => {
  const navigate = useNavigate();
  const pathname = useLocation();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getServerConfigData(setError, setIsLoaded, setItems);
  }, []);

  const onRowClick = useCallback(
    (row, type) => {
      const rowDetails = row.row.values;
      let action = type;
      if (type === 'edit') {
        action = '/edit-server-config';
      } else {
        action = '/server-config-details';
      }
      if (rowDetails) {
        navigate(action, {
          state: {
            values: {
              name: rowDetails.name,
              type: rowDetails.type,
              createdAt: rowDetails.createdAt,
              json: rowDetails.json,
            },
            pathName: pathname,
          },
        });
      }
    },
    [navigate, pathname]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'name',
        width: 190,
        minWidth: 190,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        accessor: 'createdAt',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        accessor: 'json',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        width: 75,
        maxWidth: 75,
        Cell: (row) => (
          <Cell right={true}>
            <RowEndMenu
              row={row}
              onAccessClick={() => onRowClick(row)}
              onEditClick={() => onRowClick(row, 'edit')}
            />
          </Cell>
        ),
      },
    ],
    [onRowClick]
  );

  const initialState = {
    hiddenColumns: ['json', 'createdAt'],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable({ columns, data: items, initialState }, useFilters, useSortBy);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (isLoaded) {
    return (
      <div>
        <div className='pb-8 flex flex-row justify-between'>
          <FilterInput
            id='resource'
            label='Filter by type'
            placeholder='Role'
            setfilter={(e) => setFilter('type', e.target.value)}
            showSearch={true}
            size='w-1/3'
          />
        </div>
        <Table
          clickableRow={true}
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      </div>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default ServerConfigPanel;
