export const fetchUserData = (setData, setError, setIsLoaded) => {
  fetch(
    (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/user/list',
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setData(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
};

export const getUserDetails = (
  email,
  setUser,
  setError,
  setIsLoaded,
  setIsSubmitting
) => {
  setIsSubmitting(true);
  fetch(
    (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/user/list',
    {
      method: 'POST',
      body: JSON.stringify({
        id: email,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setUser(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
    .catch((error) => {
      setIsLoaded(true);
      setError(error);
    });
};

export const deleteUser = (
  email,
  navigate,
  setError,
  setIsLoaded,
  setIsSubmitting
) => {
  setIsSubmitting(true);
  fetch(
    (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/user/remove',
    {
      method: 'DELETE',
      body: JSON.stringify({
        email: email,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    }
  )
    .then((res) => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        navigate('/user');
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
    .catch((error) => {
      setIsLoaded(true);
      setError(error);
    });
};

export const addUser = (
  form,
  navigate,
  setIsSubmitting,
  setIsLoaded,
  setError,
  values
) => {
  setIsSubmitting(true);
  let formtType = 'add';
  let method = 'PUT';
  if (values) {
    formtType = 'update';
    method = 'PATCH';
  }
  fetch(
    (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/user/' +
      formtType,
    {
      method: method,
      body: JSON.stringify({
        email: form.email,
        role: form.role,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    }
  )
    .then((res) => {
      res.json();
    })
    .then(
      (result) => {
        setIsLoaded(true);
        navigate('/user');
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
    .catch((error) => {
      setIsLoaded(true);
      setError(error);
    });
};

export const updateUserRole = (
  form,
  navigate,
  setIsSubmitting,
  setIsLoaded,
  setError
) => {
  setIsSubmitting(true);
  let formtType = 'update';
  let method = 'PATCH';
  fetch(
    (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/user/' +
      formtType,
    {
      method: method,
      body: JSON.stringify({
        email: form.email,
        role: form.role,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    }
  )
    .then((res) => {
      res.json();
    })
    .then(
      (result) => {
        setIsLoaded(true);
        navigate('/user');
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
    .catch((error) => {
      setIsLoaded(true);
      setError(error);
    });
};
