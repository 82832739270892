export const getICSData = async (setError, setICSData, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/ics-data/listFiles',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setICSData(resObject);
    await setIsLoaded(true);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const getICSFileData = async (
  file,
  folder,
  setError,
  setFileContents,
  setfileCreatedDate,
  setFileSize,
  setIsLoaded
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/ics-data/readFile',
      {
        method: 'POST',
        body: JSON.stringify({
          file: file,
          folder: folder,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setFileContents(resObject.file);
    await setfileCreatedDate(resObject.modifiedDate);
    await setFileSize(resObject.size);
    await setIsLoaded(true);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const writeICSFileData = async (
  content,
  file,
  folder,
  locationFolder,
  navigate,
  setError,
  setIsLoaded
) => {
  try {
    setIsLoaded(false);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/ics-data/writeFile',
      {
        method: 'PATCH',
        body: JSON.stringify({
          stringdata: content,
          file: file,
          folder: folder,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    navigate('/ics-data', {
      state: {
        folder: locationFolder,
        file: file,
      },
    });
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};
