// import PropTypes from 'prop-types';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import DetailsContentHeaderWButton from '../../ui/DetailsContent/DetailsContentHeaderWButton';
import Moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SvelteJSONEditor from '../../ui/SvelteJsonEditor';
import '../../../../node_modules/vanilla-jsoneditor/themes/jse-theme-dark.css';

import { defaultProps, styles } from './ICSDataPanel-constants';
import { getICSData, getICSFileData } from './ICSDataPanel-service';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const propTypes = {
  content: PropTypes.string,
  folder: PropTypes.string,
  readOnly: PropTypes.boo,
};

const ICSDataPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = useLocation();

  const [branch, setBranch] = useState('------------');
  const [commit, setCommit] = useState('------------');
  const [content, setContent] = useState({ json: {} });
  const [error, setError] = useState(null);
  const [fileContents, setFileContents] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [fileCreatedDate, setfileCreatedDate] = useState(null);
  const [file, setFile] = useState(null);
  const [folder, setFolder] = useState(null);
  const [fileOptions, setFileOptions] = useState([]);
  const [folderOptions, setFolderOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [iCSData, setICSData] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const showFileValue = useCallback(
    (inputValue) => {
      setFile(inputValue);
      setError(null);
      getICSFileData(
        inputValue.value,
        folder.label,
        setError,
        setFileContents,
        setfileCreatedDate,
        setFileSize,
        setIsLoaded
      );
    },
    [folder?.label]
  );

  const getFiles = useCallback(
    (index) => {
      setSelectedFolder(iCSData[index].folder);
      setBranch(iCSData[index].ref);
      setCommit(iCSData[index].commit);
      setFileOptions(
        iCSData[index].files.map((obj) => {
          return { label: obj, value: obj };
        })
      );
    },
    [iCSData]
  );

  const showFolderValue = useCallback(
    (inputValue) => {
      setFileContents(null);
      setFile(null);
      setError(null);
      setFolder(inputValue);
      getFiles(inputValue.value);
    },
    [getFiles]
  );

  const onClick = useCallback(() => {
    navigate('/edit-ics-data', {
      state: {
        content: content,
        folder: folder,
        file: file,
        pathName: pathname,
        selectedFolder: selectedFolder,
      },
    });
  }, [content, file, folder, navigate, pathname, selectedFolder]);

  useEffect(() => {
    getICSData(setError, setICSData, setIsLoaded);
  }, []);

  useEffect(() => {
    setFolderOptions(
      iCSData.map((obj, index) => {
        return { value: index, label: obj.folder, files: obj.files };
      })
    );
  }, [iCSData]);

  useEffect(() => {
    try {
      setContent({ json: JSON.parse(fileContents) });
    } catch (err) {
      setError(err);
    }
  }, [fileContents]);

  useEffect(() => {
    if (location.state) {
      setFolder(location.state.folder);
      if (iCSData.length === 0) {
        getICSData(setError, setICSData, setIsLoaded);
      }
      if (iCSData.length > 0) {
        showFolderValue(folder);
      }
      setFile({
        label: location.state.file,
        value: location.state.file,
      });
      if (file) {
        showFileValue(file);
      }
    }
  }, [file, folder, iCSData, location.state, showFileValue, showFolderValue]);

  const showEditor = fileContents && !error && (
    <DetailsContentWrapper styles='relative z-10'>
      <DetailsContentHeaderWButton
        content={folder.label}
        text={'Edit'}
        onClick={onClick}
      />

      <div className='p-8 mt-4 jse-theme-dark'>
        <div className='flex flex-row justify-start'>
          <div className='mr-14'>
            <span className=' font-bold'>File Size:</span> {fileSize}k
          </div>
          <div>
            <span className='font-bold'>Date Modified:</span>{' '}
            {Moment(fileCreatedDate).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </div>
        </div>
        <SvelteJSONEditor
          content={content}
          mode={'text'}
          readOnly={true}
          onChange={setContent}
        />
      </div>
    </DetailsContentWrapper>
  );

  const showError = error && (
    <div>
      <div className='mt-8'>Error: {error.message}</div>{' '}
      <div className='mt-8'>{fileContents}</div>
    </div>
  );

  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <DetailsContentWrapper styles='relative z-20'>
          <div className='p-8 flex flex-row justify-between'>
            <div className='w-1/2 mr-8'>
              <div className='mb-8'>
                <label
                  htmlFor={'folder'}
                  className='block font-bold text-sm text-white'
                >
                  Folder
                </label>
                <div className='mt-1'>
                  <Select
                    id={'folder'}
                    name={'folder'}
                    options={folderOptions}
                    placeholder={'Select a folder'}
                    styles={styles}
                    value={folder}
                    onChange={showFolderValue}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor={'file'}
                  className={`block font-bold text-sm  ${
                    folder === null ? 'text-[#7c7c7c]' : 'text-white'
                  }`}
                >
                  File
                </label>
                <div className='mt-1'>
                  <Select
                    id={'file'}
                    name={'file'}
                    options={fileOptions}
                    placeholder={'Select a file'}
                    styles={styles}
                    value={file}
                    onChange={showFileValue}
                    isDisabled={folder === null ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className='w-1/4 mr-8'>
              <div className='mb-4'>
                <label
                  htmlFor={'branch'}
                  className='block font-bold text-sm text-white'
                >
                  Branch
                </label>
                <div className='relative mt-1 flex flex-grow items-stretch focus-within:z-10'>
                  {branch}
                </div>
              </div>
            </div>
            <div className='w-1/4'>
              <div className='mb-4'>
                <label
                  htmlFor={'commit'}
                  className='block font-bold text-sm text-white'
                >
                  Commit
                </label>
                <div className='relative mt-1 flex flex-grow items-stretch focus-within:z-10'>
                  {commit}
                </div>
              </div>
            </div>
          </div>
        </DetailsContentWrapper>
        {showError}
        {showEditor}
      </>
    );
  }
};

ICSDataPanel.propType = propTypes;
ICSDataPanel.defaultProps = defaultProps;

export default ICSDataPanel;
