import PageTitle from '../components/ui/PageTitle';
import ServerConfigAddForm from '../components/pages/ServerConfig/ServerConfigAddForm';

const AddServerConfig = () => {
  return (
    <div>
      <div className='flex flex-row justify-between'>
        <PageTitle title='Add Server Config' />
      </div>
      <ServerConfigAddForm type='add' />
    </div>
  );
};
export default AddServerConfig;
