import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import FormInput from '../../ui/FormInput';

import { addToken } from './ServiceTokenService';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filterRole } from '../Roles/RoleService';
import { useEffect } from 'react';

const UserForm = (props) => {
  const { values } = props;

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [form, setForm] = useState({
    name: values?.name || '',
    role: values?.role || '',
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [doesRoleExist, setDoesRoleExist] = useState(null);
  const [roleDoesNotExist, setRoleDoesNotExist] = useState(null);

  const onChange = (e) => {
    const { value, name } = e.target;
    setForm((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (doesRoleExist) {
      addToken(form, navigate, setIsSubmitting, setIsLoaded, setError, values);
    } else if (doesRoleExist === false) {
      setRoleDoesNotExist(true);
    }
  }, [doesRoleExist, form, navigate, values]);

  const validateRole = async (role) => {
    setDoesRoleExist(await filterRole(role, setError));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await validateRole(form.role);
  };

  const showValuesOrEdit = values ? (
    <div className='py-1 w-36 text-2xl'>
      <label>
        {values.name}
        <br />
        <span className='text-lg font-normal'>{values.serviceToken}</span>
      </label>
    </div>
  ) : (
    <div>
      <FormInput
        id='name'
        name='Name'
        placeholder='Name'
        value={form.name}
        onChange={onChange}
      />
    </div>
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <form id='addToken' onSubmit={onSubmit}>
        <DetailsContentWrapper>
          <div className='name ml-8 basis-1/3'>{showValuesOrEdit}</div>
          <div>{roleDoesNotExist}</div>
          <div className={`ml-8 py-4 `}>
            <FormInput
              cls={roleDoesNotExist ? 'border-2 border-rose-500' : null}
              id='role'
              name='Role'
              placeholder='Role'
              value={form.role}
              onChange={onChange}
              className={`border-2  block w-full rounded-md p-2 bg-bg-input text-black `}
            />
            {roleDoesNotExist && (
              <Fragment>
                <div className='text-rose-300'>
                  The role you attempted to add a token to does not exist.
                  Please try again with a different role, or create the role
                  first.
                </div>
              </Fragment>
            )}
          </div>
        </DetailsContentWrapper>
      </form>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default UserForm;
