export const defaultProps = {
  values: {
    role: '',
    resources: [
      {
        resource: '',
        action: {
          create: {
            attributes: '*',
            domain: 'none',
          },
          delete: {
            attributes: '*',
            domain: 'none',
          },
          read: {
            attributes: '*',
            domain: 'none',
          },
          update: {
            attributes: '*',
            domain: 'none',
          },
        },
      },
    ],
    createdAt: {
      type: Date,
      default: Date.now,
    },
    updatedAt: {
      type: Date,
      default: Date.now,
    },
  },
};
