const DetailsContentWrapper = (props) => {
  return (
    <div
      className={`flex bg-dark-gray flex-col rounded mb-6 text-white drop-shadow ${props.styles}`}
    >
      <div>{props.children}</div>
    </div>
  );
};

export default DetailsContentWrapper;
