import PageTitle from '../components/ui/PageTitle';
import RolePanel from '../components/pages/Roles/RolePanel';

import { useNavigate } from 'react-router-dom';

const Roles = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/add-role');
  };

  return (
    <div>
      <PageTitle buttonText='Add' title='Roles' onClick={handleButtonClick} />
      <RolePanel />
    </div>
  );
};
export default Roles;
