import PageTitle from '../components/ui/PageTitle';

const Players = () => {
  return (
    <div>
      <PageTitle title='Players' />
      <div className='players'></div>
    </div>
  );
};

export default Players;
