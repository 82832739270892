import PropTypes from 'prop-types';

export const propTypesHeader = {
  content: PropTypes.string,
  imaget: PropTypes.string,
  subcontentt: PropTypes.string,
};

export const defaultPropsHeader = {
  content: null,
  imaget: null,
  subcontentt: null,
};

export const propTypesHeaderWButton = {
  content: PropTypes.string,
  image: PropTypes.string,
  subContent: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export const defaultPropsHeaderWButton = {
  content: null,
  image: null,
  subContent: null,
  text: null,
  onClick: null,
};

export const propTypesHeaderRow = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
};

export const defaultPropsHeaderRow = {
  content: null,
  label: null,
};

export const showImage = (content, image) => {
  if (image)
    return (
      <div className='mr-3'>
        <img src={image} alt={content} className='h-16 w-16' />
      </div>
    );
};
