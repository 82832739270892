const FormInput = (props) => {
  const { cls, disabled, id, name, placeholder, value, onChange } = props;
  return (
    <div className='mt-4'>
      <div className=' w-3/5 '>
        <div>
          <label className='block' htmlFor={id}>
            {name}
          </label>
          <div className='relative mt-1 rounded-md shadow-sm'>
            <input
              className={`${cls} text-black block rounded-md p-2 w-1/3 bg-bg-input`}
              disabled={disabled}
              id={id}
              name={id}
              placeholder={placeholder}
              type='text'
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormInput;
