import { useEffect, useState } from 'react';
import './ServerConfigJsonUtils';
import FormInput from '../../ui/FormInput';

const ServerConfigEditForm = (props) => {
  const { content, setContent } = props;

  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    if (
      (Object.entries(jsonData).length === 0 && content.json) ||
      (Object.entries(jsonData).length === 0 && content.text)
    ) {
      if (content.json) {
        setJsonData(JSON.flatten(content.json));
      } else {
        setJsonData(JSON.flatten(JSON.parse(content.text)));
      }
    }
  }, [jsonData, content.json, content.text]);

  useEffect(() => {
    if (
      JSON.stringify(content.json) !== null &&
      Object.entries(jsonData).length !== 0 &&
      JSON.stringify(content.json) !== JSON.stringify(JSON.unflatten(jsonData))
    ) {
      setContent({ json: JSON.unflatten(jsonData) });
    }
  }, [content, content.json, jsonData, setContent]);

  useEffect(() => {}, [content, jsonData]);

  const handleOnChange = (e) => {
    let key = e.target.id;
    let value = e.target.value;
    setJsonData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  return (
    <div className='rolesForm'>
      <div className='py-4 w-full '>
        <div className='name ml-8 basis-1/3'>
          {Object.entries(jsonData).map(([key, value], i) => (
            <FormInput
              id={key}
              key={i}
              name={key}
              value={value}
              onChange={handleOnChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServerConfigEditForm;
