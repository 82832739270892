import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

const DateRangeFilter = (props) => {
  const { fieldName, label, setFilter } = props;
  return (
    <>
      <label className='block text-sm font-bold text-black' htmlFor='role'>
        Filter by {label}
      </label>
      <div className='mt-1 flex'>
        <input
          className='block rounded-md bg-bg-input p-2  text-black w-36 h-10'
          placeholder='start'
          type='date'
          onChange={(e) => {
            const val = e.target.value;
            setFilter(fieldName, (old = []) => [val ? val : undefined, old[1]]);
          }}
        />
        <span className='block mx-4 my-2 text-black'>
          <FontAwesomeIcon icon={faMinus} />
        </span>
        <input
          className='block rounded-md bg-bg-input p-2  text-black  w-36 h-10'
          placeholder='end'
          type='date'
          onChange={(e) => {
            const val = e.target.value;
            setFilter(fieldName, (old = []) => [
              old[0],
              val ? val.concat('T23:59:59.999Z') : undefined,
            ]);
          }}
        />
      </div>
    </>
  );
};

export default DateRangeFilter;
