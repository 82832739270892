import { addServerConfigData } from './ServerConfig-Service';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import FormButtons from '../../ui/FormButtons';
import Modal from '../../ui/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SvelteJSONEditor from '../../ui/SvelteJsonEditor';
import '../../../../node_modules/vanilla-jsoneditor/themes/jse-theme-dark.css';
import FormInput from '../../ui/FormInput';

const ServerConfigAddForm = () => {
  const navigate = useNavigate();

  const [content, setContent] = useState({ json: {} });
  const [defaultConent] = useState({ json: {} });
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');

  const saveContent = (json) => {
    let stringContent;
    if (JSON.stringify(content.text)) {
      stringContent = content.text;
    } else {
      stringContent = JSON.stringify(content.json);
    }
    addServerConfigData(
      name,
      stringContent,
      type,
      navigate,
      setIsLoaded,
      setError
    );
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='mb-8 -mt-16'>
          <FormButtons
            disabled={
              JSON.stringify(content.json) ===
              JSON.stringify(defaultConent.json)
            }
            title='Save and Apply'
            onClick={() => setModalOpen(true)}
          />
        </div>
        <form id='addToken'>
          <DetailsContentWrapper>
            <div className='ml-8'>
              <FormInput
                id='name'
                name='File Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <FormInput
                id='type'
                name='File Type'
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
            </div>
            <div className='p-8 mt-4 jse-theme-dark'>
              <SvelteJSONEditor
                content={content}
                mode={'text'}
                readOnly={false}
                onChange={setContent}
              />
            </div>
          </DetailsContentWrapper>
        </form>
        <Modal
          buttonName='Confirm Changes'
          content={`Changes will be saves and applied immediately. This may cause processes to be restarted in order for the changes to take effect.`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Save Changes?`}
          onClick={saveContent}
        />
      </div>
    );
  }
};

export default ServerConfigAddForm;
