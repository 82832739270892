import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import FormInput from '../../ui/FormInput';

const UserForm = (props) => {
  const { values, form, setForm, error, isSubmitting, isLoaded } = props;

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm((state) => ({
      ...state,
      [name]: value,
    }));
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <form id='addUser'>
        <DetailsContentWrapper>
          <div className='py-4'>
            <div>
              <div className=' w-3/5 '>
                <div className='name ml-8 basis-1/3'>
                  {values && (
                    <div className='py-1 w-36'>
                      <label className='text-2xl'>
                        <span>{form.displayName}</span>
                        <br />
                        <span className='text-lg font-normal'>
                          {form.email}
                        </span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='ml-8'>
              <FormInput
                name='Role'
                placeholder='Role'
                id='role'
                value={form.role}
                onChange={onChange}
              />
            </div>
          </div>
        </DetailsContentWrapper>
      </form>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default UserForm;
