import { floatRight } from './Table-Constants';

const Cell = (props) => {
  const { right } = props;

  return (
    <div
      className={`py-2 h-full flex align-middle ${floatRight(right)}`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default Cell;
