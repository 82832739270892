import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export const filterPropTypes = {
  size: PropTypes.string,
};

export const filterDefaultProps = {
  size: 'w-full',
};

export const floatRight = (right) => {
  if (right) return 'float-right';
};

export const showSearchButton = (showSearch) => {
  if (showSearch)
    return (
      <button
        className='relative cursor-default -ml-px inline-flex items-center space-x-2 rounded-r border border-[#933b26] bg-gradient-to-b from-orange to-dark-orange px-4 text-xl'
        type='button'
      >
        <FontAwesomeIcon icon={faSearch} />
      </button>
    );
};
