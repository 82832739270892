import DetailsContentRow from '../../ui/DetailsContent/DetailsContentRow';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import DetailsContentHeaderWButton from '../../ui/DetailsContent/DetailsContentHeaderWButton';
import Moment from 'moment';
import './ServerConfigJsonUtils';

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ServerConfigDetailsPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const createdAt = Date(location.state.values.createdAt);
  const jsonObject = JSON.parse(location.state.values.json);

  const [jsonData, setJsonData] = useState({});

  const onClick = () => {
    navigate('/edit-server-config', {
      state: {
        values: {
          name: location.state.values.name,
          type: location.state.values.type,
          json: location.state.values.json,
        },
      },
    });
  };

  useEffect(() => {
    if (JSON.stringify(jsonData) !== JSON.stringify(JSON.flatten(jsonObject))) {
      setJsonData(JSON.flatten(jsonObject));
    }
  }, [jsonData, jsonObject]);

  return (
    <>
      <DetailsContentWrapper>
        <DetailsContentHeaderWButton
          content={location.state.values.name}
          subContent={location.state.values.type}
          text='Edit'
          onClick={onClick}
        />
        <DetailsContentRow
          content={`${Moment(createdAt).format('MM-DD-YYYY hh:mm:ss Z')} UTC`}
          label='Last Modified'
        />
        <DetailsContentRow
          content={`${Moment(createdAt).format('MM-DD-YYYY hh:mm:ss Z')} UTC`}
          label='Created Date'
        />
      </DetailsContentWrapper>
      <br />
      <DetailsContentWrapper>
        {Object.entries(jsonData).map(([key, value], i) => (
          <DetailsContentRow content={value} label={key} key={i} />
        ))}
      </DetailsContentWrapper>
    </>
  );
};

export default ServerConfigDetailsPanel;
