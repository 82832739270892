export const defaultProps = {
  content: null,
  folder: null,
  readOnly: true,
};

export const styles = {
  input: (styles) => ({
    ...styles,
    display: 'flex',
    color: 'black',
  }),
  control: (styles, state) => {
    const backgroundColor = state.isDisabled ? '#7c7c7c' : '#d2d2d6';
    const color = state.isDisabled ? '#3e3e40' : 'black';
    return {
      ...styles,
      border: 'none',
      paddingLeft: '15px',
      color,
      height: 42,
      backgroundColor,
    };
  },
  singleValue: (styles) => ({ ...styles, color: 'black' }),
  dropdownIndicator: (styles) => ({ ...styles, color: 'black' }),
  indicatorSeparator: (styles) => ({ ...styles, width: 0, color: 'black' }),
  indicatorsContainer: (styles) => ({ ...styles, color: 'black' }),
  valueContainer: (styles) => ({
    ...styles,
    textAlign: 'left',
    padding: 0,
    color: 'black',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#292B30',
    color: 'white',
    textAlign: 'left',
    borderRadius: '.5rem',
    fontSize: '1rem',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#CC764F' : null,
    color: 'white',
    zIndex: ' !important',
  }),
  placeholder: (styles, state) => {
    const color = state.isDisabled ? '#3e3e40' : 'black';
    return {
      ...styles,
      color,
    };
  },
};
