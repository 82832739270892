import PageTitle from '../components/ui/PageTitle';
import ServiceTokenForm from '../components/pages/ServiceTokens/ServiceTokenForm';
import FormButtons from '../components/ui/FormButtons';
import { useLocation } from 'react-router-dom';
import ServiceTokenDelete from '../components/pages/ServiceTokens/ServiceTokenDelete';

const EditToken = () => {
  const location = useLocation();
  return (
    <div>
      <div className='flex flex-row justify-between'>
        <PageTitle title='Edit Token' />
        <FormButtons form='addToken' />
      </div>
      <ServiceTokenForm values={location.state.values} />
      <ServiceTokenDelete name={location.state.values.name} />
    </div>
  );
};

export default EditToken;
