import Dropdown from '../Dropdown';

import { menuItems } from './Navbar-Constants';

const Navbar = () => {
  const displayMenu = menuItems.map((menuItem) => (
    <Dropdown
      id={menuItem.title}
      key={menuItem.title}
      logoutButton={false}
      submenus={menuItem.submenu}
      title={menuItem.title}
    />
  ));

  return (
    <nav className='h-12 text-white bg-black flex align-middle items-center mt-4 bg-gradient-to-b from-orange to-dark-orange'>
      <span className='text-xl font-bold mt-1'>{displayMenu}</span>
    </nav>
  );
};

export default Navbar;
