import PageTitle from '../components/ui/PageTitle';
import ServiceTokenForm from '../components/pages/ServiceTokens/ServiceTokenForm';
import FormButtons from '../components/ui/FormButtons';

const AddToken = () => {
  return (
    <div>
      <div className='flex flex-row justify-between'>
        <PageTitle title='Add Token' />
        <FormButtons form='addToken' />
      </div>
      <ServiceTokenForm />
    </div>
  );
};

export default AddToken;
