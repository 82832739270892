import { useEffect, useState, useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import Table from "../../ui/Table";
import Cell from "../../ui/Table/Cell";
import { getMongoData } from "./MongoDBService";

const MongoDBPanel = ({ user }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: "Database",
                accessor: "db",
                width: "20%",
                minWidth: 75,
                Cell: (row) => <Cell>{row.value}</Cell>
            },
            {
                Header: "MongoDB Role",
                accessor: "role",
                Cell: (row) => <Cell>{row.value}</Cell>
            }
        ],
        []
    );

    useEffect(() => {
        getMongoData(user, setError, setItems, setIsLoaded);
    }, []);

    const initialState = {
        hiddenColumns: []
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        { columns, data: items, initialState },
        useFilters,
        useSortBy
    );

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div>
                <Table
                    clickableRow={true}
                    getTableBodyProps={getTableBodyProps}
                    getTableProps={getTableProps}
                    headerGroups={headerGroups}
                    prepareRow={prepareRow}
                    rows={rows}
                />
            </div>
        );
    }
};

export default MongoDBPanel;
