import Button from '../Button';
import Dropdown from '../Dropdown';
import PropTypes from 'prop-types';

import { menuItems } from './User-Constants';
import { useContext } from 'react';
import { UserContext } from '../../../App';

const propTypes = {
  user: PropTypes.object,
};

const User = () => {
  const user = useContext(UserContext);

  const google = () => {
    var google_url =
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/auth/google';
    window.open(google_url, '_self');
  };

  const showUser = user.user ? (
    <Dropdown
      id={menuItems.title}
      image={user.user.image}
      logoutButton={true}
      submenus={menuItems.submenu}
    />
  ) : (
    <div className='mr-4 mt-4'>
      <Button
        clsBgColor='bg-green'
        text='Login'
        type='button'
        onClick={google}
      />
    </div>
  );

  return <div>{showUser}</div>;
};

User.propTypes = propTypes;

export default User;
