export const getRoleData = async (setError, setItems, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/list',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const getRoleDataUI = async (setError, setItems, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/listUI',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const getRoleDetailsUI = async (
  role,
  setError,
  setIsLoaded,
  setResources
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/filterUI',
      {
        method: 'POST',
        body: JSON.stringify({
          name: role,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setResources(resObject.resources);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const getRoleUsers = async (role, setData, setError, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/users',
      {
        method: 'POST',
        body: JSON.stringify({
          role: role,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setData(resObject);
    await setIsLoaded(true);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const deleteRole = async (role, resource) => {
  await fetch(
    (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
      '/api/role/remove',
    {
      method: 'DELETE',
      body: JSON.stringify({
        role: role,
        resource: resource,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    }
  );
};

export const deleteRoleUI = async (
  navigate,
  role,
  setIsLoaded,
  setError,
  setIsSubmitting
) => {
  try {
    setIsSubmitting(true);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/removeALL',
      {
        method: 'DELETE',
        body: JSON.stringify({
          role: role,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    navigate('/roles');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const addRole = async (
  form,
  navigate,
  setError,
  setIsLoaded,
  setIsSubmitting
) => {
  try {
    setIsSubmitting(true);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/add',
      {
        method: 'PUT',
        body: JSON.stringify({
          role: form.name,
          resource: form.resource,
          action: {
            create: {
              domain: form.create,
              attributes: '*',
            },
            read: {
              domain: form.read,
              attributes: '*',
            },
            update: {
              domain: form.update,
              attributes: '*',
            },
            delete: {
              domain: form.delete,
              attributes: '*',
            },
          },
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    await navigate('/roles');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const addRoleUI = async (
  form,
  navigate,
  setError,
  setIsLoaded,
  setIsSubmitting,
  type
) => {
  try {
    setIsSubmitting(true);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/addUI',
      {
        method: 'PUT',
        body: JSON.stringify(form),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    await navigate('/roles');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const editRoleUI = async (
  form,
  navigate,
  setError,
  setIsLoaded,
  setIsSubmitting
) => {
  try {
    setIsSubmitting(true);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/updateUI',
      {
        method: 'PATCH',
        body: JSON.stringify(form),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
    await navigate('/roles');
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const checkResourceValidation = async (access, resource, setError) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/resourceValidation',
      {
        method: 'POST',
        body: JSON.stringify({
          resource: resource,
          access: access,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    return resObject;
  } catch (err) {
    await setError(err);
  }
};

export const filterRole = async (role, setError) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/role/filterUI',
      {
        method: 'POST',
        body: JSON.stringify({
          name: role,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    return resObject;
  } catch (err) {
    return false;
  }
};
