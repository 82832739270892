import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

const Resource = (props) => {
  const { editForm, index, resource, onChangeResource, onHandleDelete } = props;
  
  const [selectedResource, setSelectedResource] = useState('');

  useEffect(() => {
    setSelectedResource(resource?.resource);
  }, [resource?.resource]);

  return (
    <div className='flex flex-row justify-between w-3/5 mb-4'>
      <div className='basis-1/3'>
        <label htmlFor='name' className='block font-bold'>
          Resource
        </label>
        <div className='relative mt-1 rounded-md shadow-sm'>
          <input
            className='block w-full rounded-md p-2 bg-bg-input text-black'
            disabled={!editForm && resource.new !== true}
            id='resource'
            name='resource'
            type='text'
            value={selectedResource}
            onChange={(e) => onChangeResource(e, index)}
          />
        </div>
      </div>
      <div>
        <label htmlFor='create' className='block font-bold'>
          Create
        </label>
        <select
          className='block rounded-md p-2 mt-1 w-20 bg-bg-input text-black'
          name='create'
          value={resource?.action.create.domain}
          onChange={(e) => onChangeResource(e, index)}
        >
          <option value='none'>None</option>
          <option value='own'>Own</option>
          <option value='any'>Any</option>
        </select>
      </div>
      <div>
        <label htmlFor='read' className='block font-bold'>
          Read
        </label>
        <select
          className='block rounded-md p-2 mt-1 w-20 bg-bg-input text-black'
          name='read'
          value={resource?.action.read.domain}
          onChange={(e) => onChangeResource(e, index)}
        >
          <option value='none'>None</option>
          <option value='own'>Own</option>
          <option value='any'>Any</option>
        </select>
      </div>
      <div>
        <label htmlFor='update' className='block font-bold'>
          Update
        </label>
        <select
          className='block rounded-md p-2 mt-1 w-20 bg-bg-input text-black'
          name='update'
          value={resource?.action.update.domain}
          onChange={(e) => onChangeResource(e, index)}
        >
          <option value='none'>None </option>
          <option value='own'>Own</option>
          <option value='any'>Any</option>
        </select>
      </div>
      <div>
        <label htmlFor='delete' className='block font-bold'>
          Delete
        </label>
        <select
          className='block rounded-md p-2 mt-1 w-20 bg-bg-input text-black'
          name='delete'
          value={resource?.action.delete.domain}
          onChange={(e) => onChangeResource(e, index)}
        >
          <option value='none'>None</option>
          <option value='own'>Own</option>
          <option value='any'>Any</option>
        </select>
      </div>
      {editForm && (
        <div className='text-2xl mt-9'>
          <div className='cursor-pointer' onClick={() => onHandleDelete()}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Resource;
