import PropTypes from 'prop-types';
import Select from 'react-select';
import User from '../User';

import { styles, options } from './Header-Constants';

const propTypes = {
  user: PropTypes.object,
};

const Header = () => {
  return (
    <header>
      <div className='flex justify-between h-20 pt-5'>
        <div className=' flex-col justify-start'>
          <h1 className='text-3xl font-bold text-black'>Intrepid Admin Tool</h1>
          <div className='flex justify-start text-base'>
            <Select
              defaultValue={{ value: 'dev', label: 'Development' }}
              options={options}
              styles={styles}
            />
          </div>
        </div>
        <div className='text-3xl'>
          <User />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;

export default Header;
