import DetailsContentWrapper from '../components/ui/DetailsContent/DetailsContentWrapper';
import HistoryPanel from '../components/pages/History/HistoryPanel';
import MongoDB from '../components/pages/MongoDB/MongoDB';
import PageTitle from '../components/ui/PageTitle';
import RoleDetailsPanel from '../components/pages/Roles/RoleDetailsPanel';
import ServiceTokenPanel from '../components/pages/ServiceTokens/ServiceTokenPanel';
import Tabs from '../components/ui/Tabs';
import UserDetailPanel from '../components/pages/Users/UserDetailPanel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { getUserDetails } from '../components/pages/Users/UserService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const UserDetails = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const [openTab, setOpenTab] = useState(1);
  const navigate = useNavigate();
  const [prevLocation] = useState(location?.state?.pathName);
  const [prevLink, setPrevLink] = useState('');
  const [tabs] = useState([
    {
      name: 'Access',
      href: '#',
      current: true,
      handleClick: () => handleTabClick(1),
    },
    {
      name: 'History',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(2),
    },
    {
      name: 'Tokens',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(3),
    },
    {
      name: 'Databases',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(4),
    },
  ]);
  const [user, setUser] = useState(location?.state?.values);

  const handleTabClick = (tab) => {
    let currentTab = tabs.find((tab) => tab.current);
    currentTab.current = false;
    tabs[tab - 1].current = true;
    setOpenTab(tab);
  };

  const setNavigate = () => {
    if (prevLocation === '/role-details') {
      navigate('/role-details', {
        state: {
          values: user,
        },
      });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (prevLocation === '/role-details') {
      setPrevLink('Role Details');
    } else {
      setPrevLink('Users');
    }
  }, [prevLocation]);

  useEffect(() => {
    getUserDetails(
      user?.email,
      setUser,
      setError,
      setIsLoaded,
      setIsSubmitting
    );
  }, [user?.email]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded || !isSubmitting) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='flex flex-row'>
          <button
            className='text-xl flex-none w-fit py-4 text-black'
            onClick={() => setNavigate()}
          >
            <FontAwesomeIcon className='mr-2' icon={faLeftLong} />
            {prevLink}
          </button>
        </div>
        <PageTitle title='User Details' />
        <UserDetailPanel
          createdAt={user.createdAt}
          displayName={user.displayName}
          email={user.email}
          googleId={user.googleId}
          image={user.image}
          mongoUser={user.mongoUser}
          role={user.role}
        />
        <DetailsContentWrapper styles='relative z-10'>
          <Tabs tabs={tabs} />
          {openTab === 1 && <RoleDetailsPanel user={user} />}
          {openTab === 2 && <HistoryPanel user={user} />}
          {openTab === 3 && <ServiceTokenPanel user={user} />}
          {openTab === 4 && <MongoDB user={user} />}
        </DetailsContentWrapper>
      </div>
    );
  }
};

export default UserDetails;
