import PropTypes from 'prop-types';

export const propTypes = {
  buttonName: PropTypes.string,
  cancelButtonName: PropTypes.string,
  content: PropTypes.string,
  modalOpen: PropTypes.bool,
  onClick: PropTypes.func,
  setModalOpen: PropTypes.func,
  title: PropTypes.string,
};

export const defaultProps = {
  buttonName: null,
  cancelButtonName: 'Cancel',
  content: null,
  modalOpen: null,
  onClick: null,
  setModalOpen: null,
  title: null,
};
