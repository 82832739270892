import Cell from '../../ui/Table/Cell';
import DateRangeFilter from '../../ui/Table/DateRangeFilter';
import FilterInput from '../../ui/Table/FilterInput';
import Moment from 'moment';
import PropTypes from 'prop-types';
import StatusHighlightButton from '../../ui/StatusHighlightButton';
import Table from '../../ui/Table';

import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  DefaultColumnFilter,
} from '../../ui/Table/filters';
import { defaultProps } from './History-Constants';
import {
  fetchHistoryData,
  fetchOwnHistoryData,
  fetchUserHistoryData,
} from './HistoryService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import FilterInputDropdown from '../../ui/Table/FilterInputDropdown';

const propTypes = {
  fromUserProfile: PropTypes.bool,
};

const HistoryPanel = (props) => {
  const { fromUserProfile, user, role } = props;

  const navigate = useNavigate();
  const pathname = useLocation();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (fromUserProfile) {
      fetchOwnHistoryData(setData, setError, setIsLoaded);
    } else if (user) {
      let value;
      if (user.email) {
        value = user.email;
      } else {
        value = user;
      }
      fetchUserHistoryData(value, setData, setError, setIsLoaded);
    } else {
      fetchHistoryData(setData, setError, setIsLoaded);
    }
  }, [fromUserProfile, user]);

  const onRowClick = useCallback(
    (row) => {
      const rowDetails = row.row.values;
      navigate('/history-details', {
        state: {
          values: {
            action: rowDetails.action,
            message: rowDetails['change.message'],
            passed: rowDetails['change.passed'],
            request: rowDetails['change.request'],
            resource: rowDetails.resource,
            time: rowDetails.timestamp,
            user: rowDetails.user,
          },
          pathName: pathname,
          user: user,
        },
      });
    },
    [navigate, pathname, user]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        minWidth: 270,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {Moment(row.value).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </Cell>
        ),
      },
      {
        Header: 'Resource',
        accessor: 'resource',
        width: 160,
        minWidth: 160,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'User/Token',
        accessor: 'user',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 173,
        minWidth: 173,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Message',
        accessor: 'change.message',
        disableSortBy: true,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        id: 'change.passed',
        accessor: (d) => (d.change.passed ? 'Pass' : 'Fail'),
        disableSortBy: true,
        width: 50,
        minWidth: 50,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {row.value === 'Pass' ? (
              <StatusHighlightButton
                text={row.value}
                clsBgColor='bg-green'
                clsBorderColor='border border-light-green'
              />
            ) : (
              <StatusHighlightButton
                text={row.value}
                clsBgColor='bg-red'
                clsBorderColor='border border-light-red'
              />
            )}
          </Cell>
        ),
      },
      {
        accessor: 'change.request',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
    ],
    [onRowClick]
  );

  const searchUserOrToken = useCallback((rows, ids, query) => {
    return rows.filter((row) =>
      row.values['user'].toLowerCase().includes(query.toLowerCase())
    );
  }, []);

  const initialState = {
    hiddenColumns: [fromUserProfile ? 'user' : '', 'change.request'],
  };

  const getTrProps = { onRowClick };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setFilter,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState,
      getTrProps,
      globalFilter: searchUserOrToken,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        {!fromUserProfile && !user && !role && (
          <div className='flex flex-col justify-between'>
            <FilterInput
              id='userOrToken'
              label='Filter by user or token'
              placeholder='User or Token'
              setfilter={(e) => setGlobalFilter(e.target.value)}
              showSearch={true}
              size='w-2/4'
            />
            <div className='pt-4 pb-8 flex flex-row justify-between'>
              <div className='flex flex-row justify-start'>
                <div className='w-40 mr-8'>
                  <FilterInput
                    id='resource'
                    label='Filter by Resource'
                    placeholder='Resource'
                    setfilter={(e) => setFilter('resource', e.target.value)}
                    size='w-10/12'
                  />
                </div>
                <div className='w-40 mr-8'>
                  <FilterInput
                    id='action'
                    label='Filter by Action'
                    placeholder='Action'
                    setfilter={(e) => setFilter('action', e.target.value)}
                    size='w-10/12'
                  />
                </div>
                <div className='w-40 mr-8'>
                  <FilterInputDropdown
                    id='action'
                    label='Filter by Outcome'
                    placeholder='Outcome'
                    setfilter={(e) =>
                      setFilter('change.passed', e.target.value)
                    }
                    options={[
                      { label: 'Pass', value: 'pass' },
                      { label: 'Fail', value: 'fail' },
                    ]}
                    size='w-10/12'
                  />
                </div>
              </div>
              <div className='flexflex-1'>
                <DateRangeFilter
                  fieldName='timestamp'
                  label='Date'
                  setFilter={setFilter}
                />
              </div>
            </div>
          </div>
        )}
        <Table
          clickableRow={true}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      </div>
    );
  }
};

HistoryPanel.propTypes = propTypes;
HistoryPanel.defaultProps = defaultProps;

export default HistoryPanel;
