import HistoryPanel from '../components/pages/History/HistoryPanel';
import PageTitle from '../components/ui/PageTitle';

const History = () => {
  return (
    <div>
      <PageTitle title='Access History' />
      <HistoryPanel fromUserProfile={false} />
    </div>
  );
};

export default History;
