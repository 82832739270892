import { getUser } from '../../../App-Service';

export const addMongo = async (
  setError,
  setModalOpen,
  setPassword,
  setUser,
  setUserName
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/db-user/add',
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setUserName(resObject.name);
    await setPassword(resObject.pw);
    await setModalOpen(true);
    await getUser(setUser);
  } catch (err) {
    await setError(err);
  }
};

export const getMongoData = async (
  user,
  setError,
  setItems,
  setIsLoaded,
  setIsSubmitting
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/db-user/listRoles',
      {
        method: 'POST',
        body: JSON.stringify({
          user: user,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    await setIsLoaded(true);
    await setItems(resObject);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const addMongoRoles = async (items, user, setError, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/db-user/addRole',
      {
        method: 'POST',
        body: JSON.stringify({
          user: user,
          roles: items,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    setIsLoaded(true);
    return resObject;
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const removeMongoRole = async (data, setError, setIsLoaded) => {
  try {
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/db-user/removeRoles',
      {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await setIsLoaded(true);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const deleteMongo = async (
  navigate,
  setError,
  setIsLoaded,
  setIsSubmitting,
  setUser,
  user
) => {
  try {
    setIsSubmitting(true);
    await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/db-user/delete',
      {
        method: 'DELETE',
        body: JSON.stringify({
          user: user,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    await navigate('/user');
    await getUser(setUser);
    await setIsSubmitting(false);
  } catch (err) {
    await setIsLoaded(true);
    await setError(err);
  }
};

export const isMongoDBValid = async (database, setError) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/db-user/validation',
      {
        method: 'POST',
        body: JSON.stringify({
          db: database,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    return resObject;
  } catch (err) {
    await setError(err);
  }
};
