import ICSDataPanel from '../components/pages/ICSData/ICSDataPanel';
import PageTitle from '../components/ui/PageTitle';

const ICSData = () => {
  return (
    <div>
      <PageTitle title='ICS Config' />
      <ICSDataPanel />
    </div>
  );
};

export default ICSData;
