import UserPanel from "../components/pages/Users/UserPanel";

const User = () => {
    return (
        <div className="users">
            <UserPanel />
        </div>
    )
}

export default User