export const fetchWorldConfigList = async (setData, setError, setIsLoaded) => {
    try {
        const response = await fetch(
            (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
            '/api/game-operation/world-config/list',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                },
            }
        );
        let resObject = await response.json();
        setIsLoaded(true);
        setData(resObject);
        return resObject;
    } catch (err) {
        setIsLoaded(true);
        setError(err);
    }
};

export const writeWorldConfig = async (
    config,
    navigate,
    setError,
    setIsLoaded
  ) => {
    try {
      await fetch(
        (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
          '/api/game-operation/world-config/update',
        {
          method: 'PATCH',
          body: JSON.stringify({
            world_id: config.world_id,
            active: config.active,
            allowed_tags: config.allowed_tags,
            aoc_map_path_override: config.aoc_map_path_override,
            aoc_rep_driver_cmd_override: config.aoc_rep_driver_cmd_override,
            aoc_to_exec_override: config.aoc_to_exec_override,
            build_semantic_version: config.build_semantic_version,
            capacity_bypass_tags: config.capacity_bypass_tags,
            game_server_template_version: config.game_server_template_version,
            player_count_max: config.player_count_max,
            queue_bypass_tags: config.queue_bypass_tags,
            spm_inconfig_version: config.spm_inconfig_version,
            version: config.version,
            world_display_name: config.world_display_name,
          }),
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
        }
      );
      await setIsLoaded(true);
      navigate('/world-configs');
    } catch (err) {
      await setIsLoaded(true);
      await setError(err);
    }
  };
  