import Button from '../../ui/Button';
import Modal from '../../ui/Modal';

import { deleteUser } from './UserService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const ServiceTokenDelete = (props) => {
  const { email } = props;

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <>
        <form id='deleteUser'>
          <div className='py-4 px-14 flex flex-col'>
            <div>
              <h3 className='text-xl font-bold mb-4'>Delete User</h3>
            </div>
            <div className='flex flex-row justify-between'>
              <div>
                Remove this user and any associated tokens from the tool.
                <br />
                {email}
                This connot be undone.
              </div>
              <div className='flex justify-start'>
                <Button
                  form='deleteUser'
                  text='Delete User'
                  type='button'
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>
          </div>
        </form>
        <Modal
          buttonName='Delete Token'
          content={`Remove this user and any associated tokens from the tool.. This is permanent and connot be undone. Are you sure you wish to proceed?`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Delete ${email} `}
          onClick={() =>
            deleteUser(email, navigate, setError, setIsLoaded, setIsSubmitting)
          }
        />
      </>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default ServiceTokenDelete;
