import Cell from '../../ui/Table/Cell';
import Table from '../../ui/Table';

import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import { getRoleDetailsUI } from './RoleService';

const RoleDetailsPanel = (props) => {
  const { user, role } = props;

  const location = useLocation();
  const values = location?.state?.values || user;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    if (values !== undefined) {
      getRoleDetailsUI(values.role, setError, setIsLoaded, setResources);
    } else if (role !== undefined) {
      getRoleDetailsUI(role, setError, setIsLoaded, setResources);
    }
  }, [role, values]);

  const columns = useMemo(
    () => [
      {
        Header: 'Resource',
        accessor: 'resource',
        width: 255,
        minWidth: 255,
        Cell: (row) => <Cell>{row.value}</Cell>,
      },
      {
        Header: 'Create',
        accessor: 'action.create.domain',
        width: 160,
        minWidth: 160,
        Cell: (row) => <Cell>{row.value}</Cell>,
      },
      {
        Header: 'Read',
        accessor: 'action.read.domain',
        width: 160,
        minWidth: 160,
        Cell: (row) => <Cell>{row.value}</Cell>,
      },
      {
        Header: 'Update',
        accessor: 'action.update.domain',
        width: 160,
        minWidth: 160,
        Cell: (row) => <Cell>{row.value}</Cell>,
      },
      {
        Header: 'Delete',
        accessor: 'action.delete.domain',
        disableSortBy: true,
        width: 160,
        minWidth: 160,
        Cell: (row) => <Cell>{row.value}</Cell>,
      },
    ],
    []
  );

  const initialState = {
    hiddenColumns: [],
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: resources, initialState }, useSortBy);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className='flex py-4 flex-col rounded mb-6 text-black'>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            prepareRow={prepareRow}
            rows={rows}
          />
        </div>
      </>
    );
  }
};

export default RoleDetailsPanel;
