import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useState } from 'react';

const MongoDBModal = ({ modalOpen, setModalOpen, userName, password }) => {
  const [copied, setCopied] = useState(false);

  const copy = async () => {
    await navigator.clipboard.writeText(password);
    setCopied(true);
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setModalOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-60'
          leave='ease-in duration-200'
          leaveFrom='opacity-60'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-dark-gray opacity-60 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='text-black relative transform overflow-hidden rounded-lg bg-bg-input text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[34rem]'>
                <div className='bg-dark-gray h-12'></div>
                <div className='p-10'>
                  <div className='mt-3 sm:mt-5'>
                    <div className='mt-2 mb-8'>
                      <p className='text-base text-gray-500 whitespace-pre-line'>
                        Once this window is closed this information will not be
                        viewable again. Save this information in a secure
                        location.
                      </p>
                      <div className='mt-4'>
                        Username: {userName}
                        <br />
                        Password: {password}{' '}
                        <button
                          className='bg-gradient-to-b from-orange to-dark-orange disabled:opacity-75 text-white ml-8 px-4 rounded'
                          onClick={copy}
                          disabled={!password}
                        >
                          {copied ? (
                            <>
                              <FontAwesomeIcon
                                icon={faCheck}
                                className='mr-2'
                              />
                              Copied
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faCopy} className='mr-2' />
                              Copy
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='mr-4'>
                      <button
                        type='button'
                        className=' flex-auto rounded-md bg-gradient-to-b from-[#797877] to-[#2C2A29] px-4 py-2 text-xl font-medium text-white'
                        onClick={() => [
                          setModalOpen(false),
                          // window.location.reload(false),
                        ]}
                      >
                        Close Window
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MongoDBModal;
