import Button from "../../ui/Button";
import DetailsContentRow from "../../ui/DetailsContent/DetailsContentRow";
import DetailsContentWrapper from "../../ui/DetailsContent/DetailsContentWrapper";

import { useState } from "react";
import MongoDBModal from "./MongoDBModal";
import Moment from "moment";
import { addMongo } from "./MongoDBService";
import { useContext } from "react";
import { UserContext } from "../../../App";
import StatusHighlightButton from "../../ui/StatusHighlightButton";
import MongoDBPanel from "./MongoDBPanel";

const MongoDB = (props) => {
    const { user } = props;
    const userContext = useContext(UserContext);
    const setUser = userContext.setUser;
    const [setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const onClick = () => {
        addMongo(setError, setModalOpen, setPassword, setUser, setUserName);
    };

    const showMongoDate = (password || (!user && userContext.user?.mongoUser) || user?.mongoUser) && (
        <DetailsContentRow
            content={Moment(userContext.user.mongoUser).format("MM-DD-YYYY hh:mm:ss Z")}
            label="Created Date"
        />
    );

    const showMongoPanel = user?.mongoUser && <MongoDBPanel user={user.email.substring(0, user.email.indexOf("@"))} />;

    const mongoButton = !user && !userContext?.user.mongoUser && userContext.user.role === "admin" && (
        <>
            <div className="px-8 pb-6 text-white ">
                Generate credentials to access database infomration and details.
                <div className="mt-8 -ml-8">
                    <Button text="Generate MongoDB Credentials" type="button" onClick={onClick} />
                </div>
            </div>
        </>
    );

    return (
        <div>
            <hr className="border-white" />
            <DetailsContentWrapper styles="!filter-none">
                <DetailsContentRow
                    content={
                        (!user && userContext.user?.mongoUser) || user?.mongoUser ? (
                            <StatusHighlightButton
                                clsBgColor="bg-green"
                                clsBorderColor="border border-light-green"
                                text={`Active`}
                            />
                        ) : (
                            <StatusHighlightButton
                                clsBgColor="bg-red"
                                clsBorderColor="border border-light-red"
                                text={`Not Active`}
                            />
                        )
                    }
                    label="Status"
                />
                {showMongoDate}
            </DetailsContentWrapper>
            {showMongoPanel}
            {mongoButton}
            <MongoDBModal modalOpen={modalOpen} password={password} setModalOpen={setModalOpen} userName={userName} />
        </div>
    );
};

export default MongoDB;
