import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import DetailsContentHeader from '../../ui/DetailsContent/DetailsContentHeader';
import FormButtons from '../../ui/FormButtons';
import Modal from '../../ui/Modal';
import SvelteJSONEditor from '../../ui/SvelteJsonEditor';
import '../../../../node_modules/vanilla-jsoneditor/themes/jse-theme-dark.css';

import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { writeICSFileData } from './ICSDataPanel-service';

const EditICSData = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState(location.state.content);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const saveContent = () => {
    let stringContent;
    if (JSON.stringify(content.text)) {
      stringContent = content.text;
    } else {
      stringContent = JSON.stringify(content.json);
    }
    writeICSFileData(
      stringContent,
      location.state.file.value,
      location.state.selectedFolder,
      location.state.folder,
      navigate,
      setError,
      setIsLoaded
    );
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='mb-8 -mt-16'>
          <FormButtons
            disabled={content === location.state.content}
            title='Save and Apply'
            onClick={() => setModalOpen(true)}
          />
        </div>
        <DetailsContentWrapper styles='relative z-10'>
          <DetailsContentHeader content={location.state.selectedFolder} />
          <div className='p-8 mt-4 jse-theme-dark'>
            <SvelteJSONEditor
              content={content}
              mode={'text'}
              readOnly={false}
              onChange={setContent}
            />
          </div>
        </DetailsContentWrapper>
        <Modal
          buttonName='Confirm Changes'
          content={`Changes will be saves and applied immediately. This may cause processes to be restarted in order for the changes to take effect.`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Save Changes?`}
          onClick={saveContent}
        />
      </div>
    );
  }
};

export default EditICSData;
