import PropTypes from 'prop-types';

import { defaultProps } from './Button-Constants';

const propTypes = {
  clsBgColor: PropTypes.string,
  clsTextColor: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  text: PropTypes.string,
};

const Button = (props) => {
  const { clsBgColor, clsTextColor, disabled, form, onClick, type, text } = props;

  const cls = `text-xl ${clsBgColor} ${clsTextColor} rounded-lg py-2 px-5 font-normal`;

  return (
    <div className='ml-8'>
      <button className={cls} form={form} type={type} onClick={onClick} disabled={disabled}>
        {text}
      </button>
    </div>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
