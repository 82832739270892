import {
  defaultPropsHeader,
  propTypesHeader,
  showImage,
} from './DetailsContainer-Constants';

const DetailsContentHeader = (props) => {
  const { content, subcontent, image } = props;
  return (
    <>
      <div className='my-5 px-8'>
        {showImage(content, image)}
        <span className='text-2xl font-bold'>{content}</span>
        <br />
        {subcontent}
      </div>
      <hr />
    </>
  );
};

DetailsContentHeader.propTypes = propTypesHeader;
DetailsContentHeader.defaultProps = defaultPropsHeader;

export default DetailsContentHeader;
