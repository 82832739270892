import Cell from '../../ui/Table/Cell';
import Moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../../ui/Table';

import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  DefaultColumnFilter,
} from '../../ui/Table/filters';
import { defaultProps } from '../History/History-Constants';
import { getRoleUsers } from './RoleService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';

const propTypes = {
  fromUserProfile: PropTypes.bool,
};

const RoleDetailUsers = (props) => {
  const { role } = props;

  const navigate = useNavigate();
  const pathname = useLocation();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getRoleUsers(role, setData, setError, setIsLoaded);
  }, [role]);

  const onRowClick = useCallback(
    (row) => {
      const rowDetails = row.row.values;
      navigate('/user-details', {
        state: {
          values: {
            createdAt: rowDetails.createdAt,
            displayName: rowDetails.displayName,
            email: rowDetails.email,
            googleId: rowDetails.googleId,
            image: rowDetails.image,
            role: rowDetails.role,
          },
          pathName: pathname,
        },
      });
    },
    [navigate, pathname]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'displayName',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 160,
        minWidth: 160,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        minWidth: 270,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {Moment(row.value).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </Cell>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        accessor: 'image',
        disableSortBy: true,
      },
      {
        accessor: 'googleId',
        disableSortBy: true,
      },
    ],
    [onRowClick]
  );

  const initialState = {
    hiddenColumns: ['role', 'image', 'googleId'],
  };

  const getTrProps = {};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState,
        getTrProps,
        defaultColumn: { Filter: DefaultColumnFilter },
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <Table
          clickableRow={true}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      </div>
    );
  }
};

RoleDetailUsers.propTypes = propTypes;
RoleDetailUsers.defaultProps = defaultProps;

export default RoleDetailUsers;
