export const fetchHistoryData = async (setData, setError, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/history/all',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    setIsLoaded(true);
    setData(resObject);
  } catch (err) {
    setIsLoaded(true);
    setError(err);
  }
};

export const fetchOwnHistoryData = async (setData, setError, setIsLoaded) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/history/own',
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    setIsLoaded(true);
    setData(resObject);
  } catch (err) {
    setIsLoaded(true);
    setError(err);
  }
};

export const fetchUserHistoryData = async (
  user,
  setData,
  setError,
  setIsLoaded
) => {
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || 'http://localhost:3000') +
        '/api/history/user',
      {
        method: 'POST',
        body: JSON.stringify({
          user: user,
        }),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    let resObject = await response.json();
    setIsLoaded(true);
    setData(resObject);
  } catch (err) {
    setIsLoaded(true);
    setError(err);
  }
};
