import Button from '../Button';
import Modal from '../../ui/Modal';

import { defaultProps, propTypes } from './FormButtons-Constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const FormButtons = (props) => {
  const { disabled, form, title, onClick } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const formButton = form && (
    <Button disabled={disabled} text='Submit' form={form} />
  );
  const onClickButton = onClick && (
    <Button disabled={disabled} text={title} onClick={onClick} />
  );

  return (
    <>
      <div className='flex flex-row justify-end'>
        <Button
          clsBgColor='bg-gradient-to-b from-[#797877] to-[#2C2A29]'
          text='Cancel'
          type='button'
          onClick={() => setModalOpen(true)}
        />
        {formButton}
        {onClickButton}
      </div>
      <Modal
        buttonName='Discard Changes'
        content={`You have unsaved changes to this file. Do you want to stop editing this file and discard all changes?`}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={`Discard Changes?`}
        cancelOnClick={null}
        onClick={() => navigate(-1)}
      />
    </>
  );
};

FormButtons.propTypes = propTypes;
FormButtons.defaultProps = defaultProps;

export default FormButtons;
