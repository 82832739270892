import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { isMongoDBValid } from "./MongoDBService";

const Resource = (props) => {
    const { setCanAddNewResource, setIsFormValid, index, item, onChangeDatabaseName, onHandleDelete } = props;

    const [selectedDatabaseName, setselectedDatabaseName] = useState("");
    const [isFieldValid, setIsFieldValid] = useState(true);
    useEffect(() => {
        setselectedDatabaseName(item?.db);
    }, [item?.db]);

    const validateDB = async (database) => {
        setIsFieldValid(await isMongoDBValid(database));
    };

    useEffect(() => {
        setCanAddNewResource(isFieldValid);
        setIsFormValid(isFieldValid);
    }, [isFieldValid, setCanAddNewResource, setIsFormValid]);

    return (
        <div className="flex flex-row justify-between mb-4 w-full">
            <div className="flex w-1/2">
                <div className="w-3/4">
                    <label htmlFor="name" className="block font-bold">
                        Database Name
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm mr-14">
                        <input
                            className={`block w-full rounded-md p-2 bg-bg-input text-black ${
                                isFieldValid ? "" : "border-2 border-rose-500"
                            }`}
                            disabled={item.new !== true}
                            id="databaseName"
                            name="databaseName"
                            type="text"
                            value={selectedDatabaseName}
                            onBlur={() => validateDB(selectedDatabaseName)}
                            onChange={(e) => onChangeDatabaseName(e, index)}
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="role" className="block font-bold">
                        MongoDB Role
                    </label>
                    <select
                        className="block rounded-md p-2 mt-1 w-28 bg-bg-input text-black"
                        name="role"
                        value={item.role}
                        onChange={(e) => onChangeDatabaseName(e, index)}
                    >
                        <option value="read">Read</option>
                        <option value="readWrite">Readwrite</option>
                    </select>
                </div>
            </div>
            <div className="text-2xl mt-9">
                <div className="cursor-pointer" onClick={() => onHandleDelete()}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            </div>
        </div>
    );
};

export default Resource;
