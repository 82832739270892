import PageTitle from '../components/ui/PageTitle';
import ServiceTokenDetailsPanel from '../components/pages/ServiceTokens/ServiceTokenDetailsPanel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ServiceTokenDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(location?.state?.values);
  const [prevLocation, setPrevLocation] = useState(location?.state?.pathName);
  const [prevLink, setPrevLink] = useState('');
  const [user] = useState(location?.state?.user || null);

  useEffect(() => {
    if (prevLocation === '/profile') {
      setPrevLink('Account Profile');
    } else if (prevLocation === '/user-details') {
      setPrevLink('User Details');
    } else {
      setPrevLink('Service Tokens');
    }
  }, [prevLocation]);

  useEffect(() => {
    if (location?.state) {
      setValues(location?.state?.values);
      setPrevLocation(location?.state?.pathName);
    }
  }, [location?.state]);

  const setNavigate = () => {
    if (user) {
      navigate('/user-details', {
        state: {
          values: user,
        },
      });
    } else if (prevLocation === '/role-details') {
      navigate('/role-details', {
        state: {
          values: {
            role: values.role,
          },
        },
      });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (prevLocation === '/profile') {
      setPrevLink('Account Profile');
    } else if (prevLocation === '/user-details') {
      setPrevLink('User Details');
    } else if (prevLocation === '/role-details') {
      setPrevLink('Role Details');
    } else {
      setPrevLink('Service Tokens');
    }
  }, [prevLocation]);

  return (
    <div>
      <div className='flex flex-row'>
        <button
          className='text-xl flex-none w-fit py-4 text-black'
          onClick={() => setNavigate()}
        >
          <FontAwesomeIcon className='mr-2' icon={faLeftLong} />
          {prevLink}
        </button>
      </div>
      <PageTitle title='Service Token Detials' />
      <ServiceTokenDetailsPanel
        createdAt={values.createdAt}
        createdBy={values.createdBy}
        history={values.history}
        name={values.name}
        role={values.role}
        serviceToken={values.serviceToken}
      />
    </div>
  );
};

export default ServiceTokenDetails;
