import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../ui/Table';
import Cell from '../../ui/Table/Cell';
import DateRangeFilter from '../../ui/Table/DateRangeFilter';
import RowEndMenu from '../../ui/Table/RowEndMenu';
import Moment from 'moment';
import FilterInput from '../../ui/Table/FilterInput';
import StatusHighlightButton from '../../ui/StatusHighlightButton';
import { getRoleDataUI } from './RoleService';

const RolePanel = () => {
  const navigate = useNavigate();
  const pathname = useLocation();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const onRowClick = useCallback(
    (row, type) => {
      const rowDetails = row.row.values;
      let action = type;
      if (type === 'edit') {
        action = '/edit-role';
      } else {
        action = '/role-details';
      }
      if (rowDetails) {
        navigate(action, {
          state: {
            values: {
              role: rowDetails.role,
            },
            pathName: pathname,
          },
        });
      }
    },
    [navigate, pathname]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Role',
        accessor: 'role',
        width: 190,
        minWidth: 190,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            <StatusHighlightButton text={row.value} />
          </Cell>
        ),
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {Moment(row.value).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </Cell>
        ),
      },
      {
        Header: 'Updated Date',
        accessor: 'updatedAt',
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {Moment(row.value).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </Cell>
        ),
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        width: 75,
        maxWidth: 75,
        Cell: (row) => (
          <Cell right={true}>
            <RowEndMenu
              row={row}
              onAccessClick={() => onRowClick(row)}
              onEditClick={() => onRowClick(row, 'edit')}
            />
          </Cell>
        ),
      },
      {
        accessor: '_id',
        disableSortBy: true,
      },
    ],
    [onRowClick]
  );

  useEffect(() => {
    getRoleDataUI(setError, setItems, setIsLoaded);
  }, [onRowClick]);

  const initialState = {
    hiddenColumns: [
      'action.read.domain',
      'action.update.domain',
      'action.delete.domain',
      'action.create.domain',
      '_id',
    ],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable({ columns, data: items, initialState }, useFilters, useSortBy);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='pb-8 flex flex-row justify-between'>
          <FilterInput
            id='resource'
            label='Filter by role name'
            placeholder='Role'
            setfilter={(e) => setFilter('role', e.target.value)}
            showSearch={true}
            size='w-1/3'
          />
          <div>
            <DateRangeFilter
              fieldName='createdDate'
              label='Created Date'
              setFilter={setFilter}
            />
          </div>
        </div>
        <Table
          clickableRow={true}
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      </div>
    );
  }
};

export default RolePanel;
