import PropTypes from 'prop-types';
import { Fragment } from 'react';

export const propTypes = {
  tabs: PropTypes.array,
};

export const defaultProps = {
  tabs: null,
};

export const renderTabs = (classNames, tabs) => {
  return tabs.map((tab) => (
    <Fragment key={tab.name}>
      {!tab.hide && (
        <a
          href={tab.href}
          className={classNames(
            tab.current
              ? 'border-light-orange text-light-orange font-bold'
              : 'border-transparent text-light-gray hover:text-light-orange font-medium',
            'whitespace-nowrap py-4 px-8 border-b-2 text-lg'
          )}
          aria-current={tab.current ? 'page' : undefined}
          onClick={tab.handleClick}
        >
          {tab.name}
        </a>
      )}
    </Fragment>
  ));
};
