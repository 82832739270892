import DetailsContentHeader from '../components/ui/DetailsContent/DetailsContentHeader';
import DetailsContentWrapper from '../components/ui/DetailsContent/DetailsContentWrapper';
import PageTitle from '../components/ui/PageTitle';
import ServerConfigEditForm from '../components/pages/ServerConfig/ServerConfigEditForm';
import ServerConfigEditFormJSON from '../components/pages/ServerConfig/ServerConfigEditFormJSON';
import Tabs from '../components/ui/Tabs';
import FormButtons from '../components/ui/FormButtons';
import { getServerConfigDetails } from '../components/pages/ServerConfig/ServerConfig-Service';
import Modal from '../components/ui/Modal';
import { useEffect, useState } from 'react';
import { writeServerConfig } from '../components/pages/ServerConfig/ServerConfig-Service';
import { useLocation, useNavigate } from 'react-router-dom';

const EditServerConfig = () => {
  const location = useLocation();
  const [openTab, setOpenTab] = useState(1);
  const [content, setContent] = useState({ json: {} });
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [name] = useState(location?.state?.values.name);
  const [type] = useState(location?.state?.values.type);
  const [jsonConfig, setJsonConfig] = useState(null);
  const navigate = useNavigate();
  const [tabs] = useState([
    {
      name: 'Form',
      href: '#',
      current: true,
      handleClick: () => handleTabClick(1),
    },
    {
      name: 'JSON Editor',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(2),
    },
  ]);

  const handleTabClick = (tab) => {
    let currentTab = tabs.find((tab) => tab.current);
    currentTab.current = false;
    tabs[tab - 1].current = true;
    setOpenTab(tab);
  };

  useEffect(() => {
    getServerConfigDetails(name, setError, setIsLoaded, setJsonConfig);
  }, [name]);

  useEffect(() => {
    setContent({ json: JSON.parse(jsonConfig) });
  }, [jsonConfig]);

  const saveContent = () => {
    let stringContent;
    if (JSON.stringify(content.text)) {
      stringContent = content.text;
    } else {
      stringContent = JSON.stringify(content.json);
    }
    writeServerConfig(stringContent, name, navigate, setError, setIsLoaded);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  } else {
    return (
      <div>
        <div className='flex flex-row justify-between'>
          <PageTitle title='Edit Server Config' />
          <FormButtons
            disabled={false}
            title='Save and Apply'
            onClick={() => setModalOpen(true)}
          />
        </div>
        <DetailsContentWrapper styles='relative z-10'>
          <DetailsContentHeader content={name} subcontent={type} />
          <Tabs tabs={tabs} />
          {openTab === 1 && (
            <ServerConfigEditForm content={content} setContent={setContent} />
          )}
          {openTab === 2 && (
            <ServerConfigEditFormJSON
              content={content}
              setContent={setContent}
            />
          )}
        </DetailsContentWrapper>
        <Modal
          buttonName='Confirm Changes'
          content={`Changes will be saves and applied immediately. This may cause processes to be restarted in order for the changes to take effect.`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Save Changes?`}
          onClick={saveContent}
        />
      </div>
    );
  }
};
export default EditServerConfig;
