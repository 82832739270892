import MongoDB from '../components/pages/MongoDB/MongoDB';
import PageTitle from '../components/ui/PageTitle';

const Mongo = () => {
  return (
    <div>
      <div className='px-8 pt-4'>
        <PageTitle clsColor='text-white' title='MongoDB Access' />
      </div>
      <MongoDB />
    </div>
  );
};

export default Mongo;
