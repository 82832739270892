import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export const propTypes = {
  buttonText: PropTypes.string,
  clsColor: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export const defaultProps = {
  buttonText: null,
  clsColor: 'text-black',
  onClick: null,
  title: null,
};

export const showButton = (buttonText, onClick) => {
  if (buttonText)
    return (
      <div className='ml-4 -mt-1'>
        <button
          className={` text-base bg-gradient-to-b from-orange to-dark-orange
                text-white rounded px-1 font-normal`}
          onClick={onClick}
          type='button'
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    );
};
