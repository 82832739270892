import PageTitle from '../components/ui/PageTitle';
import RoleForm from '../components/pages/Roles/RoleForm';
import FormButtons from '../components/ui/FormButtons';
import RoleDelete from '../components/pages/Roles/RoleDelete';
import { useLocation } from 'react-router-dom';

const EditRole = () => {
  const location = useLocation();

  const showDelete = location.state.values.role !== 'admin' && (
    <RoleDelete
      resource={location.state.values.resource}
      role={location.state.values.role}
    />
  );

  return (
    <div>
      <div className='flex flex-row justify-between'>
        <PageTitle title='Edit Role' />
        <FormButtons form='addRole' />
      </div>
      <RoleForm values={location.state.values} type='edit' />
      {showDelete}
    </div>
  );
};
export default EditRole;
