import { defaultProps, propTypes } from './StatusHighlightButton-Constants';
const StatusHighlightButton = (props) => {
  const { clsBgColor, clsBorderColor, text } = props;

  const cls = `p-2 h-6 ${clsBgColor} ${clsBorderColor} text-center text-sm rounded pt-0.5 inline-block`;

  return <span className={cls}>{text}</span>;
};

StatusHighlightButton.propTypes = propTypes;
StatusHighlightButton.defaultProps = defaultProps;

export default StatusHighlightButton;
