// import PropTypes from 'prop-types';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import DetailsContentHeaderWButton from '../../ui/DetailsContent/DetailsContentHeaderWButton';
import Select from 'react-select';
import Modal from '../../ui/Modal';
import SvelteJSONEditor from '../../ui/SvelteJsonEditor';
import '../../../../node_modules/vanilla-jsoneditor/themes/jse-theme-dark.css';

import { fetchWorldConfigList, writeWorldConfig } from './WorldConfigService';
import { styles } from '../ICSData/ICSDataPanel-constants';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const WorldConfigPanel = () => {
    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [worldConfig, setWorldConfig] = useState({ json: {}, text: undefined, label: "" });
    const [WorldConfigs, setWorldConfigs] = useState([]);
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [dropDownOptions, setDropDownOptions] = useState([]);


    const templist = [];

    const dropdownChanged = useCallback(
        (inputValue) => {
            console.log('dropdownChanged ' + inputValue.label);
            setSelectedDropdown(inputValue);
            setWorldConfig(inputValue.value);
        },
        [worldConfig]
    );

    const saveJson = useCallback(() => {
        console.log("save");
        console.log(worldConfig);
        if (worldConfig.text == null) {
            console.log("json item");
            setIsLoaded(false);
            writeWorldConfig(worldConfig.json, navigate, setError, setIsLoaded).finally(function (res) {
                console.log("saved");
                fetchWorldConfigList(setWorldConfigs, setError, setIsLoaded);
                setModalOpen(false);
            });

        }
        else {
            console.log("text item");
            setIsLoaded(false);
            writeWorldConfig(JSON.parse(worldConfig.text), navigate, setError, setIsLoaded).finally(function (res) {
                console.log("saved");
                fetchWorldConfigList(setWorldConfigs, setError, setIsLoaded);
                setModalOpen(false);
            });

        }
    },
        [worldConfig, WorldConfigs, isLoaded]
    );

    useEffect(() => {
        if (!isLoaded) {
            fetchWorldConfigList(setWorldConfigs, setError, setIsLoaded);
        }
    }, [

    ]);

    useEffect(() => {
        setDropDownOptions(
            WorldConfigs.map((obj, index) => {
                return { value: { json: obj, text: undefined, label: obj.world_id }, label: obj.world_id };
            })
        );
    }, [
        WorldConfigs
    ]);





    const showEditor = worldConfig && !error && (
        <DetailsContentWrapper styles='relative z-10'>
            <DetailsContentHeaderWButton
                content={worldConfig?.label || selectedDropdown?.label || ""}
                text={'Save'}
                onClick={() => setModalOpen(true)}
            />
            <div className='p-8 mt-4 jse-theme-dark'>
                <SvelteJSONEditor
                    content={worldConfig}
                    mode={'text'}
                    readOnly={false}
                    onChange={setWorldConfig}
                />
            </div>
        </DetailsContentWrapper>
    );

    const showError = error && (
        <div>
            <div className='mt-8'>Error: {error.message}</div>{' '}
            <div className='mt-8'>{templist}</div>
        </div>
    );

    if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                <DetailsContentWrapper styles='relative z-20'>
                    <div className='p-8 flex flex-row justify-between'>
                        <div className='w-1/2 mr-8'>
                            <div className='mb-8'>
                                <Modal
                                    buttonName='Confirm Changes'
                                    content={`Changes will be saved and applied immediately.`}
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    title={`Save Changes?`}
                                    onClick={saveJson}
                                />
                                <label
                                    htmlFor={'folder'}
                                    className='block font-bold text-sm text-white'
                                >
                                    World_ID
                                </label>
                                <div className='mt-1'>
                                    <Select
                                        id={'world_id'}
                                        name={'world_id'}
                                        options={dropDownOptions}
                                        placeholder={'Select a world_id'}
                                        styles={styles}
                                        value={selectedDropdown}
                                        onChange={dropdownChanged}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DetailsContentWrapper>

                {showError}
                {showEditor}
            </>
        );
    }
};


export default WorldConfigPanel;
