/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { propTypes, defaultProps } from './Modal-Constants';

const Modal = (props) => {
  const cancelButtonRef = useRef(null);

  const { buttonName, cancelButtonName, content, modalOpen, onClick, setModalOpen, title } =
    props;

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={setModalOpen}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-60'
          leave='ease-in duration-200'
          leaveFrom='opacity-60'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-dark-gray opacity-60 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='text-black relative transform overflow-hidden rounded-lg bg-bg-input text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-dark-gray h-12'>
                  <div className='text-3xl text-transparent bg-clip-text bg-gradient-to-b from-orange to-dark-orange text-right p-2'>
                    <span
                      className=' cursor-pointer'
                      onClick={() => setModalOpen(false)}
                    >
                      X
                    </span>
                    <FontAwesomeIcon icon={faCaretUp} />
                  </div>
                </div>
                <div className='p-10'>
                  <div className='mt-3 sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-2xl font-bold leading-6 text-gray-900 mb-6'
                    >
                      {title}
                    </Dialog.Title>
                    <div className='mt-2 mb-8'>
                      <p className='text-base text-gray-500 whitespace-pre-line' id='saveContent'>
                        {content}
                      </p>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='mr-4'>
                      <button
                        type='button'
                        className=' flex-auto rounded-md bg-gradient-to-b from-[#797877] to-[#2C2A29] px-4 py-2 text-xl font-medium text-white'
                        onClick={() => setModalOpen(false)}
                        ref={cancelButtonRef}
                      >
                        {cancelButtonName}
                      </button>
                    </div>
                    <div>
                      {buttonName && (
                        <button
                          type='button'
                          className='flex-auto rounded-md bg-gradient-to-b from-orange to-dark-orange px-4 py-2 text-xl font-medium text-white'
                          onClick={() => onClick()}
                        >
                          {buttonName}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
