import Cell from '../../ui/Table/Cell';
import DateRangeFilter from '../../ui/Table/DateRangeFilter';
import FilterInput from '../../ui/Table/FilterInput';
import Moment from 'moment';
import RowEndMenu from '../../ui/Table/RowEndMenu';
import StatusHighlightButton from '../../ui/StatusHighlightButton';
import Table from '../../ui/Table';

import { useCallback } from 'react';
import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  DefaultColumnFilter,
} from '../../ui/Table/filters';
import { useEffect, useMemo, useState } from 'react';
import {
  fetchServiceTokenData,
  fetchServiceTokenDataUser,
  fetchServiceTokenDataRole,
} from './ServiceTokenService';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { useNavigate, useLocation } from 'react-router-dom';

const ServiceTokenPanel = (props) => {
  const { fromUserProfile, email, user, role } = props;

  const navigate = useNavigate();
  const pathname = useLocation();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const onRowClick = useCallback(
    (row, type) => {
      const rowDetails = row.row.values;
      let action = type;
      if (type === 'edit') {
        action = '/edit-token';
      } else {
        action = '/token-details';
      }
      if (rowDetails) {
        navigate(action, {
          state: {
            values: {
              createdAt: rowDetails.createdAt,
              createdBy: rowDetails['createdBy.email'],
              history: rowDetails.history,
              name: rowDetails.name,
              role: rowDetails.role,
              serviceToken: rowDetails.serviceToken,
            },
            pathName: pathname,
            user: user,
          },
        });
      }
    },
    [navigate, pathname, user]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Service Token',
        accessor: 'name',
        width: 120,
        minWidth: 120,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 130,
        minWidth: 130,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            <StatusHighlightButton text={row.value} />
          </Cell>
        ),
      },
      {
        Header: 'Name',
        accessor: 'createdBy.email',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
        minWidth: 270,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {Moment(row.value).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </Cell>
        ),
      },
      {
        Header: 'Token Name',
        accessor: 'serviceToken',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },

      {
        Header: 'History',
        accessor: 'history',
        disableSortBy: true,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        accessor: 'actions',
        disableSortBy: true,
        width: 75,
        minWidth: 75,
        Cell: (row) => (
          <Cell right={true}>
            <RowEndMenu
              row={row}
              onAccessClick={() => onRowClick(row)}
              onEditClick={() => onRowClick(row, 'edit')}
              isToken={true}
            />
          </Cell>
        ),
      },
    ],
    [onRowClick]
  );

  useEffect(() => {
    if (fromUserProfile) {
      fetchServiceTokenDataUser(email, setError, setItems, setIsLoaded);
    } else if (user) {
      fetchServiceTokenDataUser(user.email, setError, setItems, setIsLoaded);
    } else if (role) {
      fetchServiceTokenDataRole(role, setError, setItems, setIsLoaded);
    } else {
      fetchServiceTokenData(setError, setItems, setIsLoaded);
    }
  }, [email, fromUserProfile, role, user]);

  const initialState = {
    hiddenColumns: ['serviceToken', 'history', 'delete'],
  };

  const searchTokenOrName = useCallback((rows, ids, query) => {
    return rows.filter(
      (row) =>
        row.values['name'].toLowerCase().includes(query.toLowerCase()) ||
        row.values['createdBy.email']
          .toLowerCase()
          .includes(query.toLowerCase())
    );
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: items,
      defaultColumn: { Filter: DefaultColumnFilter },
      globalFilter: searchTokenOrName,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        {!fromUserProfile && !user && !role && (
          <div className='pb-8 flex flex-row justify-between'>
            <FilterInput
              id='nameOrEmail'
              label='Filter by user name or token'
              placeholder='Token or Username'
              showSearch={true}
              setfilter={(e) => setGlobalFilter(e.target.value)}
            />
            <div className='flex-1 ml-8'>
              <FilterInput
                id='role'
                label='Filter by role'
                placeholder='Role'
                size='w-1/3'
                setfilter={(e) => setFilter('role', e.target.value)}
              />
            </div>
            <div>
              <DateRangeFilter
                fieldName='createdAt'
                label='Created Date'
                setFilter={setFilter}
              />
            </div>
          </div>
        )}
        <Table
          clickableRow={true}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      </div>
    );
  }
};

export default ServiceTokenPanel;
