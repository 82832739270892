import {
  filterDefaultProps,
  filterPropTypes,
  showSearchButton,
} from './Table-Constants';

const FilterInput = (props) => {
  const { id, label, placeholder, setfilter, showSearch, size } = props;

  const clsSize = `rounded-l block bg-bg-input p-2  text-black  h-10 placeholder:italic ${size}`;

  return (
    <div className='flex-1'>
      <label htmlFor={id} className='block font-bold text-sm text-black'>
        {label}
      </label>
      <div className='relative mt-1 flex flex-grow items-stretch focus-within:z-10'>
        <input
          className={clsSize}
          id={id}
          name={id}
          placeholder={placeholder}
          type='text'
          onChange={setfilter}
        />
        {showSearchButton(showSearch)}
      </div>
    </div>
  );
};

FilterInput.propTypes = filterPropTypes;
FilterInput.defaultProps = filterDefaultProps;

export default FilterInput;
