import {
  defaultPropsHeaderRow,
  propTypesHeaderRow,
} from './DetailsContainer-Constants';

const DetailsContentRow = (props) => {
  const { content, label } = props;
  return (
    <>
      <div className='my-5 px-8'>
        <div className='flex'>
          <div className='w-44'>
            <label className='font-bold mr-2 '>{label}: </label>{' '}
          </div>
          <div>
            <span>{content}</span>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

DetailsContentRow.propTypes = propTypesHeaderRow;
DetailsContentRow.defaultProps = defaultPropsHeaderRow;

export default DetailsContentRow;
