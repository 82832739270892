import Button from '../../ui/Button';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import FormInput from '../../ui/FormInput';
import PropTypes from 'prop-types';
import Resource from './Resource';

import { addRoleUI, deleteRole, editRoleUI } from './RoleService';
import { defaultProps } from './Roles-Constants';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const propTypes = {
  values: PropTypes.object,
};

const RoleForm = (props) => {
  const { values, type } = props;

  const navigate = useNavigate();

  const [form, setForm] = useState(values);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editForm, setEditForm] = useState(true);

  const onChangeResource = (e, index) => {
    const { name, value } = e.target;
    let tempForm = form;
    let tempResources = tempForm.resources;
    let tempResource = tempResources[index];
    if (name === 'resource') {
      tempResource.resource = value;
      setForm((state) => ({
        ...state,
      }));
    } else {
      tempResource.action[name].domain = value;
      setForm((state) => ({
        ...state,
      }));
    }
  };

  const onHandleAddResource = () => {
    setForm((state) => ({
      ...state,
      resources: state.resources.concat({
        new: true,
        resource: '',
        action: {
          create: {
            attributes: '*',
            domain: 'none',
          },
          delete: {
            attributes: '*',
            domain: 'none',
          },
          read: {
            attributes: '*',
            domain: 'none',
          },
          update: {
            attributes: '*',
            domain: 'none',
          },
        },
      }),
    }));
  };

  const onHandleDelete = (deletedResource, deleteRole, role) => {
    setForm((state) => ({
      ...state,
      resources: state.resources.filter(
        (resource) => resource.resource !== deletedResource.resource
      ),
    }));
    deleteRole(role, deletedResource.resource);
  };

  const onRoleChange = (e) => {
    const { value } = e.target;
    setForm((state) => ({
      ...state,
      role: value,
    }));
  };

  const onSubmit = (e, addRoleUI, form, navigate, type) => {
    e.preventDefault();
    if (props.type === 'edit') {
      editRoleUI(form, navigate, setError, setIsLoaded, setIsSubmitting);
    } else {
      addRoleUI(form, navigate, setError, setIsLoaded, setIsSubmitting);
    }
  };

  const showResources = (
    deleteRole,
    form,
    setForm,
    editForm,
    onChangeResource,
    onHandleDelete
  ) => {
    return form?.resources?.map((resource, index) => {
      return (
        <Resource
          index={index}
          key={index}
          resource={resource}
          resources={form.resources}
          role={form.role}
          setForm={setForm}
          editForm={editForm}
          onChangeResource={(e) => onChangeResource(e, index, form, setForm)}
          onHandleDelete={() =>
            onHandleDelete(resource, deleteRole, form.role, setForm)
          }
        />
      );
    });
  };

  useEffect(() => {
    if (values.role === 'admin') setEditForm(false);
    if (type !== 'add') {
      setForm(values);
    } else {
      setForm({
        role: '',
        resources: [
          {
            resource: '',
            action: {
              create: {
                attributes: '*',
                domain: 'none',
              },
              delete: {
                attributes: '*',
                domain: 'none',
              },
              read: {
                attributes: '*',
                domain: 'none',
              },
              update: {
                attributes: '*',
                domain: 'none',
              },
            },
          },
        ],
      });
    }
  }, [type, values, values.resources, values.role]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <>
        <form
          id='addRole'
          onSubmit={(e) =>
            onSubmit(
              e,
              addRoleUI,
              form,
              navigate,
              setError,
              setIsLoaded,
              setIsSubmitting,
              values?.type
            )
          }
        >
          <DetailsContentWrapper>
            <div className='rolesForm'>
              <div className='ml-8 pb-4'>
                <FormInput
                  disabled={!editForm}
                  name='Role'
                  placeholder='Role'
                  id='role'
                  value={form.role}
                  onChange={(e) => onRoleChange(e)}
                />
              </div>
            </div>
          </DetailsContentWrapper>
          <DetailsContentWrapper>
            <div className='py-4 px-8'>
              <div>
                <h3 className='text-2xl font-bold mb-4'>Resources</h3>
              </div>
              {showResources(
                deleteRole,
                form,
                setForm,
                editForm,
                onChangeResource,
                onHandleDelete
              )}
              <div className='mt-8 -ml-8'>
                <Button
                  text='Add Resource'
                  type='button'
                  onClick={() => onHandleAddResource()}
                />
              </div>
            </div>
          </DetailsContentWrapper>
        </form>
      </>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

RoleForm.propTypes = propTypes;
RoleForm.defaultProps = defaultProps;

export default RoleForm;
