import { useState } from 'react';
import UserProfile from '../components/pages/Profile/Profile';
import Mongo from './Mongo';
import PageTitle from '../components/ui/PageTitle';
import { useContext } from 'react';
import { UserContext } from '../App';
import UserProfileHistory from '../components/pages/Profile/ProfileHistory';
import UserProfileTokens from '../components/pages/Profile/ProfileTokens';
import Tabs from '../components/ui/Tabs';
import { useEffect } from 'react';
import { checkResourceValidation } from '../components/pages/Roles/RoleService';

const Profile = () => {
  const user = useContext(UserContext);
  const [openTab, setOpenTab] = useState(1);
  const [error, setError] = useState(null);
  const [dbAccess, setDBAccess] = useState(false);
  const [tabs, setTabs] = useState([
    {
      name: 'History',
      href: '#',
      current: true,
      handleClick: () => handleTabClick(1),
    },
    {
      name: 'Tokens',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(2),
    },
    {
      name: 'Databases',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(3),
      hide: true,
    },
  ]);

  const validateResource = async (access, resource, setError) => {
    setDBAccess(await checkResourceValidation(access, resource, setError));
  };

  const handleTabClick = (tab) => {
    let currentTab = tabs.find((tab) => tab.current);
    currentTab.current = false;
    tabs[tab - 1].current = true;
    setOpenTab(tab);
  };

  useEffect(() => {
    validateResource('createOwn', 'db-user', setError);
  }, []);

  useEffect(() => {
    if (dbAccess) {
      setTabs((state) => [...state, (state[2].hide = false)]);
    }
  }, [dbAccess]);

  return (
    <div>
      <PageTitle title='Account Profile' />
      <div>
        <UserProfile user={user.user} />
      </div>
      <div className='rounded bg-dark-gray text-black'>
        <Tabs tabs={tabs} />
        {openTab === 1 && <UserProfileHistory fromUserProfile={true} />}
        {openTab === 2 && !error && (
          <UserProfileTokens fromUserProfile={true} email={user.user.email} />
        )}
        {openTab === 3 && <Mongo user={user.user} />}
      </div>
      {error && <div>Error: {error}</div>}
    </div>
  );
};
export default Profile;
