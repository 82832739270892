export const options = [
  { value: 'dev', label: 'Development' },
  { value: 'stage', label: 'Staging' },
  { value: 'prod', label: 'Production' },
];

export const styles = {
  input: (styles) => ({
    ...styles,
    display: 'flex',
    color: 'black',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    color: 'black',
  }),
  singleValue: (styles) => ({ ...styles, color: 'black' }),
  dropdownIndicator: (styles) => ({ ...styles, color: 'black' }),
  indicatorSeparator: (styles) => ({ ...styles, width: 0, color: 'black' }),
  indicatorContainer: (styles) => ({ ...styles, color: 'black' }),
  valueContainer: (styles) => ({
    ...styles,
    textAlign: 'left',
    padding: 0,
    color: 'black',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#292B30',
    color: 'white',
    textAlign: 'left',
    borderRadius: '.5rem',
    fontSize: '1rem',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#CC764F' : null,
    color: 'white',
  }),
};
