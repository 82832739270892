import Button from '../Button';
import {
  defaultPropsHeaderWButton,
  propTypesHeaderWButton,
  showImage,
} from './DetailsContainer-Constants';

const DetailsContentHeaderWButton = (props) => {
  const { content, image, subContent, text, onClick } = props;
  return (
    <>
      <section className='flex flex-row justify-between px-8'>
        <div className='flex flex-row my-5 w-full justify-between'>
          <div className='flex flex-row'>
            {showImage(content, image)}
            <div className=''>
              <span className='text-2xl font-bold'>{content}</span>
              <br />
              {subContent}
            </div>
          </div>
          <div className='text-right'>
            <Button text={text} type='button' onClick={onClick} />
          </div>
        </div>
      </section>
      <hr />
    </>
  );
};

DetailsContentHeaderWButton.propTypes = propTypesHeaderWButton;
DetailsContentHeaderWButton.defaultProps = defaultPropsHeaderWButton;

export default DetailsContentHeaderWButton;
