import Cell from '../../ui/Table/Cell';
import DateRangeFilter from '../../ui/Table/DateRangeFilter';
import FilterInput from '../../ui/Table/FilterInput';
import Moment from 'moment';
import StatusHighlightButton from '../../ui/StatusHighlightButton';
import Table from '../../ui/Table';

import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  DefaultColumnFilter,
} from '../../ui/Table/filters';
import { fetchUserData } from './UserService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';

const UserPanel = () => {
  const navigate = useNavigate();
  const pathname = useLocation();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchUserData(setData, setError, setIsLoaded);
  }, []);

  const onRowClick = useCallback(
    (row) => {
      const rowDetails = row.row.values;
      navigate('/user-details', {
        state: {
          values: {
            createdAt: rowDetails.createdAt,
            displayName: rowDetails.displayName,
            email: rowDetails.email,
            googleId: rowDetails.googleId,
            image: rowDetails.image,
            mongoUser: rowDetails.mongoUser,
            role: rowDetails.role,
          },
          pathName: pathname,
        },
      });
    },
    [navigate, pathname]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'displayName',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            <StatusHighlightButton text={row.value} />
          </Cell>
        ),
      },
      {
        Header: 'MongoDB',
        accessor: 'mongoUser',
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {row.value ? (
              <StatusHighlightButton
                clsBgColor='bg-green'
                clsBorderColor='border border-light-green'
                text={`Active`}
              />
            ) : (
              ''
            )}
          </Cell>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
        Cell: (row) => (
          <Cell onClick={() => onRowClick(row)}>
            {Moment(row.value).format('MM-DD-YYYY hh:mm:ss Z')} {'UTC'}
          </Cell>
        ),
      },
      {
        accessor: 'image',
        disableSortBy: true,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
      {
        accessor: 'googleId',
        disableSortBy: true,
        Cell: (row) => <Cell onClick={() => onRowClick(row)}>{row.value}</Cell>,
      },
    ],
    [onRowClick]
  );

  const initialState = {
    hiddenColumns: ['googleId', 'image'],
  };

  const getTrProps = { onRowClick };

  const searchUserOrEmail = useCallback((rows, ids, query) => {
    return rows.filter(
      (row) =>
        row.values['displayName'].toLowerCase().includes(query.toLowerCase()) ||
        row.values['email'].toLowerCase().includes(query.toLowerCase())
    );
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      getTrProps,
      globalFilter: searchUserOrEmail,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='pb-8 flex flex-row justify-between'>
          <FilterInput
            id='nameOrEmail'
            label='Filter by user name or email'
            placeholder='Name or Email'
            setfilter={(e) => setGlobalFilter(e.target.value)}
            showSearch={true}
          />
          <div className='flex-1 ml-8'>
            <FilterInput
              id='role'
              label='Filter by role'
              placeholder='Role'
              setfilter={(e) => setFilter('role', e.target.value)}
              size='w-1/3'
            />
          </div>
          <div>
            <DateRangeFilter
              fieldName='createdAt'
              label='Created Date'
              setFilter={setFilter}
            />
          </div>
        </div>
        <Table
          clickableRow={true}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          rows={rows}
        />
      </div>
    );
  }
};

export default UserPanel;
