import PageTitle from '../components/ui/PageTitle';
import ServiceTokenPanel from '../components/pages/ServiceTokens/ServiceTokenPanel';

import { useNavigate } from 'react-router-dom';

const Service = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/add-token');
  };

  return (
    <div>
      <PageTitle
        buttonText='Add'
        title='Service Tokens'
        onClick={handleButtonClick}
      />
      <ServiceTokenPanel />
    </div>
  );
};

export default Service;
