import FormButtons from '../components/ui/FormButtons';
import ICSDataEdit from '../components/pages/ICSData/EditICSData';
import PageTitle from '../components/ui/PageTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const EditICSData = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='flex flex-row justify-between'>
        <PageTitle title='Edit ICS Config' />
      </div>
      <ICSDataEdit />
    </div>
  );
};

export default EditICSData;
