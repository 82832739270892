export const menuItems = [
  {
    title: 'Admin Tools',
    submenu: [
      {
        title: 'Users',
        link: '/user',
      },
      {
        title: 'Roles',
        link: '/roles',
      },
      {
        title: 'Service Tokens',
        link: '/service',
      },
      {
        title: 'Access History',
        link: '/history',
      },
    ],
  },
  {
    title: 'Game Accounts',
    submenu: [
      {
        title: 'Players',
        link: '/players',
      },
      {
        title: 'Comms',
        link: '/communications',
      },
    ],
  },
  {
    title: 'Servers',
    submenu: [
      {
        title: 'ICS Data',
        link: '/ics-data',
      },
      {
        title: 'Patches',
        link: '/patcher',
      },
      {
        title: 'Server Config',
        link: '/server-config',
      },
      {
        title: 'World Config',
        link: '/world-configs',
      },
    ],
  },
];
