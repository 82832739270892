import DetailsContentHeader from '../../ui/DetailsContent/DetailsContentHeader';
import DetailsContentRow from '../../ui/DetailsContent/DetailsContentRow';
import DetailsContentWrapper from '../../ui/DetailsContent/DetailsContentWrapper';
import Moment from 'moment';
import PropTypes from 'prop-types';
import StatusHighlightButton from '../../ui/StatusHighlightButton';

import { defaultProps } from './Profile-Constants';
import { useContext } from 'react';
import { UserContext } from '../../../App';

const propTypes = {
  user: PropTypes.object,
};

const UserProfile = () => {
  const user = useContext(UserContext);
  const formatDate = Moment(user.user.createdAt).format(
    'MM-DD-YYYY hh:mm:ss Z'
  );

  return (
    <DetailsContentWrapper>
      <div>
        <DetailsContentHeader
          content={user.user.displayName}
          subcontent={user.user.email}
        />
      </div>
      <div>
        <DetailsContentRow
          content={<StatusHighlightButton text={user.user.role} />}
          label='Role'
        />
        <DetailsContentRow content={user.user.googleId} label='Created Date' />
        <DetailsContentRow
          content={`${formatDate.toString()} UTC`}
          label='GoogleID'
        />
      </div>
    </DetailsContentWrapper>
  );
};

UserProfile.propTypes = propTypes;
UserProfile.defaultProps = defaultProps;

export default UserProfile;
