import Button from '../../ui/Button';
import Modal from '../../ui/Modal';

import { deleteMongo } from './MongoDBService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../../App';

const MongoDBDelete = (props) => {
  const { user } = props;
  const userContext = useContext(UserContext);
  const setUser = userContext.setUser;
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isSubmitting) {
    return (
      <>
        <form id='deleteUser'>
          <div className='py-4 px-14 flex flex-col'>
            <div>
              <h3 className='text-xl font-bold mb-4'>
                Delete MongoDB Credentials
              </h3>
            </div>
            <div className='flex flex-row justify-between'>
              <div>
                Permanently removes these credentials from MongoDB.
                <br />
                This connot be undone.
              </div>
              <div className='flex justify-start'>
                <Button
                  form='deleteCredentials'
                  text='Delete Credentials'
                  type='button'
                  onClick={() => setModalOpen(true)}
                />
              </div>
            </div>
          </div>
        </form>
        <Modal
          buttonName='Confirm'
          content={`Are you sure you want to delete these credentials? This will permanently delete the account and all associated information in MongoDB. This cannont be undone.`}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={`Delete MongoDB Credentials `}
          onClick={() =>
            deleteMongo(
              navigate,
              setError,
              setIsSubmitting,
              setIsLoaded,
              setUser,
              user
            )
          }
        />
      </>
    );
  } else if (!isLoaded) {
    return <div>Submitting...</div>;
  }
};

export default MongoDBDelete;
