import DetailsContentWrapper from '../components/ui/DetailsContent/DetailsContentWrapper';
import PageTitle from '../components/ui/PageTitle';
import RoleDetailsPanel from '../components/pages/Roles/RoleDetailsPanel';
import RoleDetailUsers from '../components/pages/Roles/RoleDetailUsers';
import ServiceTokenPanel from '../components/pages/ServiceTokens/ServiceTokenPanel';
import Tabs from '../components/ui/Tabs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import RoleTitle from '../components/pages/Roles/RoleTitle';
import { useEffect } from 'react';

const RoleDetails = () => {
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState(1);
  const location = useLocation();
  const [role, setRole] = useState(location?.state?.values?.role);
  const [tabs] = useState([
    {
      name: 'Access',
      href: '#',
      current: true,
      handleClick: () => handleTabClick(1),
    },
    {
      name: 'Users',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(2),
    },
    {
      name: 'Tokens',
      href: '#',
      current: false,
      handleClick: () => handleTabClick(3),
    },
  ]);
  const handleTabClick = (tab) => {
    let currentTab = tabs.find((tab) => tab.current);
    currentTab.current = false;
    tabs[tab - 1].current = true;
    setOpenTab(tab);
  };

  useEffect(() => {
    if (location?.state) setRole(location?.state?.values?.role);
  }, [location?.state, role]);

  return (
    <div>
      <div className='flex flex-row'>
        <button
          className='text-xl flex-none w-fit py-4 text-black'
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon className='mr-2' icon={faArrowLeft} />
          Roles
        </button>
      </div>
      <PageTitle title='Role Details' />
      <RoleTitle role={role} />
      <DetailsContentWrapper styles='relative z-10'>
        <Tabs tabs={tabs} />
        {openTab === 1 && <RoleDetailsPanel role={role} />}
        {openTab === 2 && <RoleDetailUsers role={role} />}
        {openTab === 3 && <ServiceTokenPanel role={role} />}
      </DetailsContentWrapper>
    </div>
  );
};
export default RoleDetails;
